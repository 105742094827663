import types from "../types/pipelineListingTypes";

const initialState = {
  manageModelsData: [],
  confirmationModal: '',
  disableDeployBtn: true,
  disableDeleteBtn: true,
  disableUndeployBtn: true,
  awsProjectDetails:{},
  awsProjectById: {},
  modelMonitorData:{}

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MODELS_MANAGE:
      return {...state, manageModelsData:action.data};
    case types.SELECT_MANAGE_MODEL:
      const data = state.manageModelsData.map(
        (value, i) => value.version === action.data.version ? {...value, checked: value.uuid}
                                : value
    );
    let disableDeploy = false;
    let disableDelete = false;
    let disableUndeploy = true;
    
    if(action.data.status === 'Deployed'){
      disableUndeploy = false;
      disableDelete = true;
      }
      return {...state, manageModelsData: data, 
        disableDeployBtn: disableDeploy,
        disableDeleteBtn: disableDelete,
        disableUndeployBtn: disableUndeploy
      
      };

    case types.AWS_PROJECT_DESCRIPTION:
      return{...state, awsProjectDetails: action.data};
    case types.AWS_PROJECT_BY_ID:
        return{...state, awsProjectById: action.data};
    case types.GET_MODEL_MONITOR:
      return{...state, modelMonitorData: action.data}
  
    default:
      return state;
  }
};

export default reducer;

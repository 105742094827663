import React, { createRef, useEffect} from "react";
import closeIcon from "../../img/close.svg";
import "./Modal.scss";

const Modal = ({
	title,
	onClose,
	className = "",
	children,
	modalState,
	onClickOutside,
	hideHeader = false,
}) => {
	useEffect(() => {
		function keyListener(e) {
			const listener = keyListenersMap.get(e.keyCode);
			return listener && listener(e);
		}
		document.addEventListener("keydown", keyListener);

		return () => document.removeEventListener("keydown", keyListener);
	}, []);

	const modalRef = createRef();

	const handleTabKey = (e) => {
		if (modalRef.current) {
			const focusableModalElements = modalRef.current.querySelectorAll(
				'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
			);
			const firstElement = focusableModalElements[0];
			const lastElement =
				focusableModalElements[focusableModalElements.length - 1];

			if (!e.shiftKey && document.activeElement !== firstElement) {
				firstElement.focus();
				return e.preventDefault();
			}

			if (e.shiftKey && document.activeElement !== lastElement) {
				lastElement.focus();
				e.preventDefault();
			}
		}
	};

	const keyListenersMap = new Map([
		[27, onClose],
		[9, handleTabKey],
	]);

	return (
		modalState && (
			<div className={`modal-backdrop ${className}`} onClick={onClickOutside}>
				<div className='modal-container'>
					{!hideHeader && (
						<div className='modal-header'>
							<h1 className='title'>{title}</h1>
							<img
								onClick={onClose}
								className='close'
								src={closeIcon}
								alt='close'
							/>
						</div>
					)}
					<div className='modal-body'>{children}</div>
				</div>
			</div>
		)
	);
};

export default Modal;

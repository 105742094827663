import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import pipelineListingReducer from "./reducers/pipelineListingReducer";
import loginReducer from "./reducers/loginReducer";
import createPipelineReducer from "./reducers/createPipelineReducer";
import manageModelReducer from "./reducers/manageModelReducer";
import pipeLineDetailsReducer from "./reducers/pipeLineDetailsReducer";
import s3StorageConfiguration from "./reducers/s3StorageConfigReducer";
import labelStudioReducer from "./reducers/labelStudioReducer";
import responsiveViewReducer from "./reducers/responsiveViewReducer";
import trainModelReducer from "./reducers/trainModelReducer";
import importDatasetReducer from "./reducers/importDatasetReducer";
import popupReducer from "./reducers/popupReducer";
import modelMonitoringReducer from "./reducers/modelMontioringReducer"

const rootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		pipeLineDetails: pipeLineDetailsReducer,
		pipelineListing: pipelineListingReducer,
		loginUser: loginReducer,
		createPipeline: createPipelineReducer,
		manageModel: manageModelReducer,
		s3StorageConfiguration: s3StorageConfiguration,
		labelStudioDetails: labelStudioReducer,
		responsiveView: responsiveViewReducer,
		trainModel: trainModelReducer,
		importDataset: importDatasetReducer,
		popup: popupReducer,
		modelMonitor: modelMonitoringReducer
	});

export default rootReducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/button-component/Button";
import Input from "../../components/input-component/Input";
import Modal from "../../components/modal-component/Modal";
import types from "../../types/pipelineListingTypes";
import deleteAlert from "../../img/delete-alert.svg";
import {
  createGCPStorage,
  deleteGCPStorage,
  deleteStorage,
  syncStorage,
  createStorage,
  listStorage,
} from "../../actions/storageConfigurationAction";
import "./ConfigureDataSource.scss";
import deleteIcon from "../../img/delete.svg";
import syncIcon from "../../img/sync.svg";
import add from "../../img/add.svg";
import Tabs from "../../components/tabs/Tabs";
import Tab from "../../components/tabs/Tab";
import FileUpload from "../../components/file-upload/File-upload";
import { BASE_URL } from "../../utils/config/api.config";
import { getImportConfigs } from "../../actions/labelPageActions";
import arrowProfileDown from "../../img/arrow2.svg";
import AWSimg from "../../img/aws.svg";
import oneReachIcon from "../../img/onereach.svg";
import gcpIcon from "../../img/gcp.svg";
import labelStudioIcon from "../../img/labelstudio.svg";
import { deleteAllTasks } from "../../actions/importDatasetAction";

export const ConfigureDataSource = (props) => {
  const [piplelineName, setPipelineName] = useState("");
  const [endpoint, setEndPoint] = useState("");
  const [deleteModal, setDeleteModal] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [dropdownData, setDropDownData] = useState([]);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const stage = useSelector((state) => {
    return state.pipeLineDetails?.stage;
  });
  const projectDetails = useSelector(
    (state) => state.pipelineListing?.pipelineFlow
  );
  const storageInfo = useSelector((state) => {
    return state.s3StorageConfiguration?.storage.storages;
  });
  const cloudProvider = projectDetails?.cloud_service_provider;
  const supportedFilesTypes = ["application/json"];
  useEffect(() => {
    if (projectDetails.annotation_project_id) {
      dispatch(
        listStorage({
          project_id: projectDetails?.annotation_project_id,
        })
      );
    }
  }, [projectDetails]);

  useEffect(() => {
    const data = { pipeline_id: projectDetails.pipeline_id };
    dispatch(getImportConfigs(data)).then((res) => {
      setDropDownData(res);
      res.length && setSelectedItem(res[0]);
    });
  }, []);

  const handleSelect = (item) => {
    setSelectedItem(item);
    setProfileDropdown(false);
  };

  const onChangeName = ({ event }) => {
    setPipelineName(event?.target?.value);
  };

  const onAdd = () => {
    const annotation_id = projectDetails?.annotation_project_id;
    let use_blob_urls;

    if (
      projectDetails?.use_case === "image_classification_multi_class" ||
      projectDetails?.use_case === "image_classification_multi_label" ||
      projectDetails?.use_case === "object_detection"
    ) {
      use_blob_urls = true;
    } else if (
      projectDetails?.use_case === "text_classification_multi_class" ||
      projectDetails?.use_case === "text_classification_multi_label" ||
      projectDetails?.use_case === "natural_entity_recognition"
    ) {
      use_blob_urls = false;
    } else {
      use_blob_urls = true;
    }
    if (cloudProvider.toLowerCase() === "aws") {
      dispatch(
        createStorage({
          project: annotation_id,
          pipeline_id: projectDetails?.pipeline_id,
          title: piplelineName,
          description: piplelineName,
          bucket: piplelineName,
          use_blob_urls: use_blob_urls,
        })
      );
    } else {
      dispatch(
        createGCPStorage({
          project: annotation_id,
          title: piplelineName,
          description: piplelineName,
          bucket: piplelineName,
          use_blob_urls: use_blob_urls,
        })
      );
    }
    setPipelineName("");
  };

  const handleDeleteS3Storage = () => {
    if (cloudProvider.toLowerCase() === "aws") {
      dispatch(
        deleteAllTasks({
          project_id: projectDetails?.annotation_project_id,
        })
      ).then(() => {
        dispatch(deleteStorage({ storage_id: deleteModal })).then(() => {
          setDeleteModal(null);
        });
      });
    } else {
      dispatch(
        deleteAllTasks({
          project_id: projectDetails?.annotation_project_id,
        })
      ).then(() => {
        dispatch(deleteGCPStorage({ storage_id: deleteModal })).then(() => {
          setDeleteModal(null);
        });
      });
    }
  };

  const syncS3Storage = (id) => {
    dispatch(syncStorage({ storage_id: id }));
    dispatch(
      listStorage({
        project_id: projectDetails?.annotation_project_id,
      })
    );
  };

  const mapDropdownImage = (item) => {
    switch (item?.config_value) {
      case "aws":
        return AWSimg;
      case "gcp":
        return gcpIcon;
      case "Label Studio":
        return labelStudioIcon;
      case "One Reach":
        return oneReachIcon;
      default:
        return AWSimg;
    }
  };

  const onUpload = () => {
    setSubmit(true);
    const data = {
      pipeline_id: projectDetails?.pipeline_id,
      format: selectedItem?.config_value,
    };
    setEndPoint(
      BASE_URL + "/label_studio/upload_data?" + new URLSearchParams(data)
    );
  };
  return (
    <>
      <Tabs className="configure-data-source-tabs">
        <Tab title="Cloud Bucket">
          <div className="configure-data-source">
            <h1 className="configure-data-source-heading">Cloud Bucket Name</h1>
            <div className="configure-data-source-add">
              <Input
                className="configure-data-source-add-input"
                name="pipelineName"
                onChange={(event) => onChangeName(event)}
                value={piplelineName}
                placeholder="Enter Your Bucket "
              />
              <Button
                disabled={piplelineName === ""}
                className="configure-data-source-add-button"
                onClick={() => onAdd()}
              >
                <img src={add} alt="add-new" />
              </Button>
              <p className="configure-data-source-add-note">
                TIP: Atleast 100 samples should be labelled to create an
                efficient model.
              </p>
            </div>
            <div className="configure-data-source-sync">
              {storageInfo?.map((item, i) => (
                <div className="configure-data-source-sync-card" key={item.id}>
                  <div className="configure-data-source-sync-card-header">
                    <h2 className="configure-data-source-sync-card-header-name">
                      {item.title}
                    </h2>
                    <img
                      className={`configure-data-source-sync-card-delete ${
                        !item.last_sync ? "disable-delete" : null
                      }`}
                      src={deleteIcon}
                      alt="delete"
                      onClick={() => setDeleteModal(item.id)}
                    />
                  </div>
                  <div className="configure-data-source-sync-state">
                    {!item.last_sync ? (
                      <div className="configure-data-source-sync-state-container">
                        <img
                          className="configure-data-source-sync-state-rotate loading"
                          src={syncIcon}
                          alt="syncing"
                        />
                        <span className="configure-data-source-syncing">
                          Syncing
                        </span>
                      </div>
                    ) : (
                      <div className="configure-data-source-date">
                        <p className="last-sync-text">Last Sync</p>
                        <span className="last-sync-time">
                          {new Date(item.last_sync).toLocaleString()}
                        </span>
                      </div>
                    )}
                    <div>
                      <Button
                        className="configure-data-source-sync-button"
                        theme="ghost"
                        disabled={!item.last_sync}
                        onClick={() => syncS3Storage(item.id)}
                      >
                        Sync
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="page-container-footer">
            <Button
              onClick={() =>
                dispatch({
                  type: types.SET_STAGE,
                  data: {
                    stage: stage + 1,
                  },
                })
              }
              className="page-container-footer-button"
            >
              Proceed
            </Button>
          </div>
        </Tab>
        <Tab title="Upload File">
          <div className="configure-data-source">
            <div className="upload-file-tab">
              <div className="upload-data-wrapper">
                <FileUpload
                  submit={submit}
                  supportedFilesTypes={supportedFilesTypes}
                  handleComplete={() => setSubmit(false)}
                  endpoint={endpoint}
                  fileUploadKey="upload_file"
                  setFile={setFile}
                  fileKey="file"
                />
                <div className="upload-data-section">
                  <div
                    className="dropdown-upload-wrapper"
                    onClick={() => setProfileDropdown(!profileDropdown)}
                  >
                    <img
                      src={mapDropdownImage(selectedItem)}
                      alt="logo"
                      className="selected-logo"
                    />
                    <span className="dd-title-text">
                      {selectedItem?.config_value}
                    </span>
                    <img
                      src={arrowProfileDown}
                      className={`${profileDropdown && "arrow-up"} arrow`}
                      alt="arrow-down"
                    />
                    {profileDropdown && (
                      <ul
                        className="download-dropdown"
                        onClickOutside={() => setProfileDropdown(false)}
                      >
                        {dropdownData?.map((option) => (
                          <li
                            className="download-dd-list"
                            type="link"
                            key={option?.config_value}
                            onClick={() => handleSelect(option)}
                          >
                            <span>{option?.config_value}</span>
                            <img
                              src={mapDropdownImage(option)}
                              alt={option.config_value}
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <Button
                    className="upload-data-btn"
                    onClick={() => onUpload()}
                    disabled={!file}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </div>
            <div className="page-container-footer">
              <Button
                onClick={() =>
                  dispatch({
                    type: types.SET_STAGE,
                    data: {
                      stage: stage + 1,
                    },
                  })
                }
                className="page-container-footer-button"
              >
                Proceed
              </Button>
            </div>
          </div>
        </Tab>
      </Tabs>
      <Modal
        title="Are you sure you want to remove the data source?"
        modalState={deleteModal !== null}
        onClose={() => setDeleteModal(null)}
        className="delete-conform-modal"
      >
        <div className="delete-conform-modal-wrapper">
          <div className="message-wrapper">
            <img alt="warningIcon" src={deleteAlert} />
            <div className="message">
              Once a data source is removed, we lose the respective labelled
              data as well. The already trained models will remain unaffected.
            </div>
          </div>
          <div className="button-group">
            <Button
              theme="ghost"
              className="add-more"
              onClick={() => handleDeleteS3Storage()}
            >
              Remove
            </Button>
            <Button onClick={() => setDeleteModal(null)}>Cancel</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

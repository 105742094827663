import React from "react";
import Stepper from "../stepper-component/Stepper";
import awsLogo from "../../img/aws.svg";
import gcpLogo from "../../img/gcp.svg";
import "./PipelineDetails.scss";
import { useSelector } from "react-redux";

const PipelineDetails = (props) => {
  const { pipelineName, pipelineDesc, platform, children } = props;

  const stepperData = useSelector((state) => {
    return state.pipeLineDetails?.stepperData[state.pipeLineDetails?.platform];
  });

  const setLogoImage = () => {
    switch (platform) {
      case "aws":
        return awsLogo;
      case "gcp":
        return gcpLogo;
      default:
        break;
    }
  };
  return (
    <div className="pipeline-details-wrapper">
      <div className="pipeline-details-header">
        <div className="pipeline-details">
          <span className="pipeline-name">
            {pipelineName?.replace("_", " ")}
          </span>
          <span className="pipeline-desc">
            {pipelineDesc?.replace("_", " ")}
          </span>
        </div>
        <div className="pipeline-img">
          <img src={setLogoImage()} alt="img" />
        </div>
      </div>
      <div className="pipeline-details-body">
        <Stepper stepperData={stepperData} />
        <div className="page-container">{children}</div>
      </div>
    </div>
  );
};
export default PipelineDetails;

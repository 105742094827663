import types from "../types/pipelineListingTypes";
import {
  API_URL,
  RequestMethod,
  defaultHeader,
} from "../utils/config/api.config";

const listData = (data) => ({
  type: types.LIST_STORAGE,
  data,
});

const createData = (data) => ({
  type: types.CREATE_STORAGE,
  data,
});

const createGCPData = (data) => ({
  type: types.CREATE_GCP_STORAGE,
  data,
});

export const purgeData = () => ({
  type: types.PURGE_DATA,
});
const syncData = (data) => ({
  type: types.SYNC_STORAGE,
  data,
});

const syncGCPData = (data) => ({
  type: types.SYNC_GCP_STORAGE,
  data,
});

const deleteData = (data) => ({
  type: types.DELETE_STORAGE,
  data,
});

const deleteGCPData = (data) => ({
  type: types.DELETE_GCP_STORAGE,
  data,
});

export const listStorage = (params) => async (dispatch) => {
  try {
    const response = await fetch(
      API_URL.listStorage + "?" + new URLSearchParams(params),
      {
        method: RequestMethod.GET,
        headers: defaultHeader(),
      }
    );
    const res = await response.json();

    dispatch(listData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const syncStorage = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.syncStorage, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch({
      type: types.POPUP_TRIGGER,
      data: {
        message: "Pipeline synced",
        type: "success",
      },
    });
    dispatch(syncData(res));
  } catch (err) {
    dispatch({
      type: types.POPUP_TRIGGER,
      data: {
        message: "message",
        type: "error",
      },
    });
    console.log("ERROR", err);
  }
};

export const syncGCPStorage = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.syncGCPstorage, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    dispatch({
      type: types.POPUP_TRIGGER,
      data: {
        message: "Pipeline synced",
        type: "success",
      },
    });
    const res = await response.json();
    dispatch(syncGCPData(res));
  } catch (err) {
    console.log("ERROR", err); dispatch({
      type: types.POPUP_TRIGGER,
      data: {
        message: "message",
        type: "error",
      },
    });
  }
};

export const deleteStorage = (params) => async (dispatch) => {
  try {
    await fetch(API_URL.deleteStorage, {
      method: RequestMethod.DELETE,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    dispatch(deleteData(params.storage_id));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const deleteGCPStorage = (params) => async (dispatch) => {
  try {
      await fetch(API_URL.deleteGCPStorage, {
      method: RequestMethod.DELETE,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    dispatch(deleteGCPData(params.storage_id));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const createStorage = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.createStorage, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(syncStorage({ storage_id: res.id }));
    dispatch(createData(res));
  } catch (err) {
    dispatch({
      type: types.POPUP_TRIGGER,
      data: {
        message:"There was an unexpected error",
        type: "error",
      },
    });
    console.log("ERROR", err);
  }
};

export const createGCPStorage = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.createGCPstorage, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(syncGCPStorage({ storage_id: res.id }));
    dispatch(createGCPData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

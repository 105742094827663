import types from "../types/pipelineListingTypes";
import {
  API_URL,
  RequestMethod,
  defaultHeader,
} from "../utils/config/api.config";

const setData = (data) => ({
  type: types.SET_GCP_DATASET,
  data,
});

const imgClassificationData = (data) => ({
  type: types.GET_IMGCLASSIFICATION_DATASET,
  data,
});

const objClassificationData = (data) => ({
  type: types.GET_OBJCLASSIFICATION_DATASET,
  data,
});

const nerDataset = (data) => ({
  type: types.GET_NER_DATASET,
  data,
});

const txtClassification = (data) => ({
  type: types.GET_TXTCLASSIFICATION_DATASET,
  data,
});

const importData = (data) => ({
  type: types.IMPORT_DATA,
  data,
});

const deleteData = (data) => ({
  type: types.DELETE_DATASET,
  data,
});

const deleteTasks = (data) =>({
  type: types.DELETE_ALL_TASKS,
  data
})
export const getDatasetByID = (params) => async (dispatch) => {
  try {
    const response = await fetch(
      API_URL.getGCPDataset + "?" + new URLSearchParams(params),
      {
        method: RequestMethod.GET,
        headers: defaultHeader(),
      }
    );
    const res = await response.json();
    const temp = res.alias_name;
    delete res.alias_name;
    res.dataset_name = temp;
    dispatch(setData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};
export const datasetImageClassification = (params) => async (dispatch) => {
  try {
    return await fetch(API_URL.imgClassificationDataset, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((res) => dispatch(imgClassificationData(res)));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const datasetTextClassification = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.textClassificationDataset, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(txtClassification(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const datasetObjectClassification = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.objClassificationDataset, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(objClassificationData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const createNerDataset = (params) => async (dispatch)=>{
  try {
    const response = await fetch(API_URL.nerDataset, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(nerDataset(res));
  } catch (err) {
    console.log("ERROR", err);
  }
}
export const importNewDataset = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.importDataset, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(importData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const deleteDataset = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.deleteDataset, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(deleteData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const deleteAllTasks = (params) => async (dispatch) => {
    try {
      const response = await fetch(API_URL.deleteAllTask, {
        method: RequestMethod.DELETE,
        headers: defaultHeader(),
        body: JSON.stringify(params),
      });
      const res = await response.json();
       dispatch(deleteTasks(res));
    }
  catch(err){

  }

}


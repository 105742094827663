import types from "../types/pipelineListingTypes";
import { stepMapper } from "../constants/Constants";
import {
  API_URL,
  RequestMethod,
  defaultHeader,
} from "../utils/config/api.config";

const setData = (data) => ({
  type: types.SET_PIPELINE_LIST,
  data,
});

const setFlow = (data) => ({
  type: types.SET_PIPELINE_FLOW,
  data,
});

export const getPipelineData = () => async (dispatch) => {
  try {
    const response = await fetch(API_URL.getPipelines, {
      method: RequestMethod.GET,
      headers: defaultHeader(),
    });
    const res = await response.json();
    // const ifDisable = res?.find((item)=>!item.last_sync);
    dispatch(setData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const deletePipelineByID = (id) => async (dispatch) => {
  return await fetch(API_URL.deletePipeline + '?pipeline_id=' + id, {
    method: RequestMethod.DELETE,
    headers: defaultHeader(),
  }).then(res => res.json())
  .then(res => {
    dispatch({
      type: types.DELETE_PIPELINE,
      data: id
    })
    dispatch({
      type: types.POPUP_TRIGGER,
      data: {
        message: `“${res.pipeline_name}” deleted successfully`,
        type: 'success'
      } 
    })
  })
  .catch(error => {
    dispatch({
      type: types.POPUP_TRIGGER,
      data: {
        message: error.message,
        type: 'error'
      } 
    })
  })
}

export const getPipelinesFlow = (params) => async (dispatch) => {
  try {
    dispatch({
      type: types.RESET_DATA,
    });
    const response = await fetch(
      API_URL.getProjectFlow + "?" + new URLSearchParams(params),
      {
        method: RequestMethod.GET,
        headers: defaultHeader(),
      }
    );
    const res = await response.json();

    dispatch(setFlow(res));
    dispatch({
      type: types.SET_STAGE,
      data: {
        stage: stepMapper[res?.cloud_service_provider][res?.current_stage],
        platform: res.cloud_service_provider,
      },
    });
  } catch (err) {
    console.log("ERROR", err);
  }
};




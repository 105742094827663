import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./LabelDataPage.scss";
import labelStudioIcon from "../../img/labelstudio.svg";
import linkIcon from "../../img/link.svg";
import copyLinkIcon from "../../img/copy-link.svg";
import Input from "../../components/input-component/Input";
import Button from "../../components/button-component/Button";
import types from "../../types/pipelineListingTypes";
import {
  updateOutputBucket,
  getProjectDescription,
  getCloudUri,
  downloadAnnotations,
  getDropdownValues,
} from "../../actions/labelPageActions";
import warningAlert from "../../img/warningIcon.svg";
import Modal from "../../components/modal-component/Modal";
import { copyContent } from "../../functions/nebulaHelper";
import arrowProfileDown from "../../img/arrow2.svg";
import AWSimg from "../../img/aws.svg";
import oneReachIcon from "../../img/onereach.svg";
import gcpIcon from "../../img/gcp.svg";
import magicStickIcon from "../../img/magic-stick.svg";
import AssistiveLabeling from "../../components/assistive-labeling/AssistiveLabeling";
import CheckBox from "../../components/checkbox/CheckBox";

export const LabelDataPage = (props) => {
  const dispatch = useDispatch();
  const [exportCheckbox, setExportCheckbox] = useState(false);
  const [bucketName, setBucketName] = useState("");
  const [limitModal, setLimitModal] = useState(null);
  const [isDownloadable, setIsDownloadable] = useState(false);
  const [assistiveModel, setAssistiveModel] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [dropdownData, setDropDownData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({
    config_value: "Download Data",
  });
  const stage = useSelector((state) => {
    return state.pipeLineDetails?.stage;
  });
  const projectDescription = useSelector(
    (state) => state.labelStudioDetails.projectDescription
  );
  const projectDetails = useSelector(
    (state) => state.pipelineListing?.pipelineFlow
  );

  useEffect(() => {
    const { annotation_project_id } = projectDetails;
    if (annotation_project_id) {
      dispatch(getProjectDescription(annotation_project_id));
    }
    const data = { pipeline_id: projectDetails.pipeline_id };
    dispatch(getDropdownValues(data)).then((res) => setDropDownData(res));
  }, []);

  const labelStudioLink = `${process.env.REACT_APP_LABELSTUDIO_URL}/${projectDetails?.annotation_project_id}/data`;

  useEffect(() => {
    if (parseInt(projectDescription.total_annotations_number) > 0) {
      setIsDownloadable(true);
    }
  }, [projectDescription.total_annotations_number]);

  const openLinkInNewTab = () => {
    window.open(labelStudioLink, "_blank").focus();
  };
  const handleProceed = (exit) => {
    if (bucketName && projectDetails.pipeline_id) {
      dispatch(
        getCloudUri(
          {
            pipeline_id: projectDetails.pipeline_id,
            project_id: projectDetails.annotation_project_id,
            service_provider: projectDetails.cloud_service_provider,
            bucket_name: bucketName,
          },
          stage
        )
      ).then((_) => {
        if (exit) {
          props.history.push("/pipelines");
        } else {
          dispatch({
            type: types.SET_STAGE,
            data: {
              stage: stage + 1,
            },
          });
        }
      });
    }
  };

  const handleProceedModal = async () => {
    if (bucketName && projectDetails.pipeline_id) {
      await dispatch(
        getCloudUri(
          {
            pipeline_id: projectDetails.pipeline_id,
            project_id: projectDetails.annotation_project_id,
            service_provider: projectDetails.cloud_service_provider,
            bucket_name: bucketName,
          },
          stage
        )
      )
        .then((res) => {
          dispatch({
            type: types.SET_STAGE,
            data: {
              stage: stage + 1,
            },
          });
        })
        .catch((err) => {
          console.log("error label", err);
        })
        .finally((_) => setLimitModal(null));
    }
  };

  const mapDropdownImage = (item) => {
    switch (item.config_value) {
      case "aws":
        return AWSimg;
      case "gcp":
        return gcpIcon;
      case "Label Studio":
        return labelStudioIcon;
      case "One Reach":
        return oneReachIcon;
      default:
        return "";
    }
  };
  const handleSelect = (item) => {
    setSelectedItem(item);
    setProfileDropdown(false);
    const data = {
      pipeline_id: projectDetails.pipeline_id,
      format: item.config_value,
    };
    dispatch(downloadAnnotations(data));
  };

  return (
    <>
      <div className="label-data-page">
        <h1 className="label-data-page-heading">Label Studio Info</h1>
        <div className="label-data-page-row">
          <div className="label-studio-details">
            <div className="label-studio-details-left">
              <img src={labelStudioIcon} alt="" />
              <p className="label-studio-details-text">
                <span>{projectDescription.total_annotations_number}</span>{" "}
                {`/ ${projectDescription.task_number} data labeled`}
              </p>
            </div>
            <div>
              <img
                className="label-studio-details-new-tab"
                src={linkIcon}
                onClick={() => openLinkInNewTab()}
                alt=""
              />
            </div>
          </div>
          <div className="copy-link-container">
            <span
              className="label-data-page-row-link"
              onClick={() => copyContent(labelStudioLink)}
            >
              <img className="copy-link-icon" src={copyLinkIcon} alt="" />
              Copy Link
            </span>
          </div>
          <p className="label-data-page-row-text">
            TIP: Atleast 100 samples should be labelled to create an efficient
            model.
          </p>
        </div>
        <div className="label-data-export">
          <CheckBox
            option=" I want to export the labeled data"
            checked={exportCheckbox}
            onChange={() => setExportCheckbox(!exportCheckbox)}
            _labelClassName="label-data-export-text"
          />
        </div>
        <div className="export-bucket-container">
          <Input
            label="Export Bucket Name"
            name="export bucket"
            labelClassName="export-bucket-container-text"
            placeholder="Export Bucket"
            className="export-bucket-container-input"
            onChange={({ value }) => {
              setBucketName(value);
              dispatch(updateOutputBucket(value));
            }}
            disabled={!exportCheckbox}
          />
          <p className="label-data-page-row-text">
            TIP: The name of the cloud bucket where you want to store the
            labelled data. If the bucket name is not found, a new one will be
            created.
          </p>
        </div>
      </div>
      <div className="proceed-save-exit-container">
        <div className="label-page-footer-left">
          <img src={magicStickIcon} alt="assistive labelling" />
          <p onClick={() => setAssistiveModel(!assistiveModel)}>
            Start Assistive Labeling
          </p>
          {assistiveModel ? (
            <AssistiveLabeling
              assistiveModel={assistiveModel}
              setAssistiveModel={setAssistiveModel}
            />
          ) : (
            ""
          )}
        </div>
        <div className="label-page-footer-right">
          <div
            className={` ${
              isDownloadable ? "" : "disable-download"
            } dropdown-download-wrapper`}
            onClick={() => setProfileDropdown(!profileDropdown)}
          >
            <div className="dd-selected-item">
              {mapDropdownImage(selectedItem) && (
                <img
                  src={mapDropdownImage(selectedItem)}
                  alt="logo"
                  className="selected-logo"
                />
              )}
              <span className="dd-title-text">{selectedItem.config_value}</span>
            </div>
            <img
              src={arrowProfileDown}
              className={`${
                isDownloadable && profileDropdown && "arrow-up"
              } arrow`}
              alt="arrow-down"
            />
            {isDownloadable && profileDropdown && (
              <ul
                className="download-dropdown"
                onClickOutside={() => setProfileDropdown(false)}
              >
                {dropdownData.map((option) => (
                  <li
                    className="download-dd-list"
                    type="link"
                    key={option.config_value}
                    onClick={() => handleSelect(option)}
                  >
                    <span>{option.config_value}</span>
                    <img
                      src={mapDropdownImage(option)}
                      alt={option.config_value}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
          {parseInt(projectDescription.total_annotations_number) < 100 ? (
            <Button
              onClick={() => setLimitModal(true)}
              className="page-container-footer-button"
              disabled={
                !exportCheckbox ||
                bucketName === "" ||
                projectDescription.total_annotations_number === 0
              }
            >
              Export
            </Button>
          ) : (
            <Button
              onClick={() => handleProceed()}
              className="page-container-footer-button"
              disabled={!exportCheckbox || bucketName === ""}
            >
              Proceed
            </Button>
          )}
        </div>
      </div>
      <Modal
        title="Insufficient Labelled Data"
        modalState={limitModal !== null}
        onClose={() => setLimitModal(null)}
        className="delete-conform-modal"
      >
        <div className="delete-conform-modal-wrapper">
          <div className="message-wrapper">
            <img alt="warningIcon" src={warningAlert} />
            <div className="message">
              You have not labelled sufficient samples. Label atleast 100
              samples to create an efficient model.
            </div>
          </div>
          <div className="button-group">
            <Button
              theme="ghost"
              className="add-more"
              onClick={() => setLimitModal(null)}
            >
              Cancel
            </Button>
            <Button onClick={() => handleProceedModal()}>Proceed Anyway</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

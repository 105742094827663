import types from "../types/pipelineListingTypes";
import {
  API_URL,
  RequestMethod,
  defaultHeader,
} from "../utils/config/api.config";

const setData = (data) => ({
  type: types.SET_USER,
  data,
});

const newUser = (data) => ({
  type: types.REGISTER_USER,
  data,
});

export const getUser = (params) => async (dispatch) => {
  return new Promise(function (resolve, reject) {
    fetch(API_URL.loginUser, {
      method: RequestMethod.POST,
      body: params,
    })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.json());
        } else {
          reject(res.json());
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const registerUser = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.registerUser, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(newUser(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const logoutUser = () => (dispatch) => {
  sessionStorage.clear();
  dispatch(setData({}));
};

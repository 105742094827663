import React, { useState } from "react";
import Dropdown from "../../components/dropdown-component/Dropdown";

const DeployModalBody = (props) => {
  const [isListOpen, setIsListOpen] = useState(false);

  return (
    <div className="deploy-modal-body">
      <Dropdown
        label="Select number of inference units"
        title={props.selectedItem}
        className="deploy-units-dropdown"
        list={props.dropdownOptions}
        isListOpen={isListOpen}
        setIsListOpen={setIsListOpen}
        setSelectedItem={props.setSelectedItem}
        selectedItem={props.selectedItem}
      />
      <div className="body-text">
        Select a higher number of interface units to increase the throughput of
        your model. You are charged for each additional interface unit used.
      </div>
    </div>
  );
};

export default DeployModalBody;

import types from "../types/pipelineListingTypes";
import {
  API_URL,
  RequestMethod,
  defaultHeader,
} from "../utils/config/api.config";

import {
  getApiFromUseCase,
  parseManageModelData,
} from "../functions/manage-model";

export const postManageModelAction = (data) => async (dispatch) => {
  // const { url, params } = getApiFromUseCase('aws',data.type,data.useCase,data.params);
  const totalParmeters = {
    ...data.params,
    model_arn: data.selectedData.modelId,
    endpoint_name: data.selectedData.alias_name,
    endpoint_arn: data.selectedData.model_deployment_data[0]
      ?.deployment_endpoint
      ? data.selectedData.model_deployment_data[0]?.deployment_endpoint
      : "",
    DocumentClassifierArn: data.selectedData.modelId,
    EntityRecognizerArn: data.selectedData.modelId,
  };
  const { url, params } = getApiFromUseCase(
    API_URL,
    "aws",
    data.type,
    data.useCase,
    totalParmeters
  );
  return fetch(url, {
    method: RequestMethod.POST,
    headers: defaultHeader(),
    body: JSON.stringify(params),
  });
};

export const getModelData = (params) => async (dispatch) => {
  try {
    const response = await fetch(
      API_URL.getModelsByPipline + "?pipeline_id=" + params,
      {
        method: RequestMethod.GET,
        headers: defaultHeader(),
      }
    );
    const res = await response.json();
    let tempRes = res.filter((x) => x.status !== "MODEL_DELETED");
    const data = parseManageModelData(tempRes);
    dispatch({
      type: types.GET_MODELS_MANAGE,
      data,
    });
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const getAWSDescription = (params) => async (dispatch) => {
  // try {
  //   const response = await fetch(
  //     API_URL.awsRecokprojectdetails,
  //     {
  //       method: RequestMethod.POST,
  //       headers: defaultHeader(),
  //       body: JSON.stringify(params)
  //     }
  //   );
  //   const res = await response.json();
  //   dispatch(setProjectData(res));
  // } catch (err) {
  //   console.log("ERROR", err);
  // }
};

export const getProjectByID = (params) => async (dispatch) => {
  // try {
  //   return await fetch(
  //     API_URL.awsRecokprojectByID + "?" + new URLSearchParams(params),
  //     {
  //       method: RequestMethod.GET,
  //       headers: defaultHeader(),
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((res) => dispatch(setProjectDataById(res)));
  // } catch (err) {
  //   console.log("ERROR", err);
  // }
};

const setData = (data) => ({
  type: types.GET_MODEL_MONITOR,
  data,
});

export const getMonitorData = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.modelMonitor + "?model_id=" + params, {
      method: RequestMethod.GET,
      headers: defaultHeader(),
    });
    const res = await response.json();
    dispatch(setData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

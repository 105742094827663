import { Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./protected.route";
import "./App.scss";
import "./styles/styles.scss";
import SignUpPage from "./pages/sign-up/SignUpPage";
import SignInPage from "./pages/sign-in/SignInPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import PipelineDetailsPage from "./pages/pipeline-details-page/PipelineDetailsPage";
import Header from "./components/header-component/Header";
import ModelMonitor from "./pages/model-monitoring/ModelMonitor";

function App() {
	return (
		<div className='App'>
			<Header />
			<div className='app-body'>
				<Switch>
					<Route exact path='/' component={SignInPage} />
					<Route exact path='/signup' component={SignUpPage} />
					<Route exact path='/signin' component={SignInPage} />
					<ProtectedRoute exact path='/pipelines' component={Dashboard} />
					{/* <ProtectedRoute exact path='/pipelines/model-monitoring' component={ModelMonitor} /> */}
					
					<ProtectedRoute
						exact
						path='/pipelinedetails/:id'
						component={PipelineDetailsPage}
					/>
					<ProtectedRoute
				
				path='/pipelinedetails/:id/:model_id'
				component={ModelMonitor}
			/>
					
					<Route path='*' component={() => "404 NOT FOUND"} />
				</Switch>
			</div>
		</div>
	);
}

export default App;

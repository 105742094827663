export const BASE_URL = process.env.REACT_APP_BASE_URL; 
export const CONTEXT_URL = BASE_URL;
export const RequestMethod = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
};

export const signInHeader = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Accept: "application/json",
  dataType: "json",
  // CSRFToken: window?.ACC?.config?.CSRFToken || "",
};

export const defaultHeader = () => {
  const access_token = sessionStorage.getItem("access_token");
  return {
    Authorization: `Bearer ${access_token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
    "Access-Control-Allow-Headers":
      "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
    Accept: "application/json",
  };
};

export const API_URL = {
  getPipelines: `${CONTEXT_URL}/mobius/project_flow`,
  deletePipeline: `${CONTEXT_URL}/mobius/project_flow`,
  loginUser: `${CONTEXT_URL}/user/login`,
  registerUser: `${CONTEXT_URL}/user/register`,
  newPipeline: `${CONTEXT_URL}/mobius/project_flow/create`,
  createStorage: `${CONTEXT_URL}/label_studio/create_s3_storage`,
  createGCPstorage:`${CONTEXT_URL}/label_studio/create_gcs_storage`,
  syncStorage: `${CONTEXT_URL}/label_studio/sync_s3_storage`,
  syncGCPstorage:`${CONTEXT_URL}/label_studio/sync_gcs_storage`,
  deleteAllTask:`${CONTEXT_URL}/label_studio/delete_all_tasks`,
  deleteStorage: `${CONTEXT_URL}/label_studio/delete_s3_storage`,
  deleteGCPStorage:`${CONTEXT_URL}/label_studio/delete_gcs_storage`,
  listStorage: `${CONTEXT_URL}/label_studio/list_storages`,
  getProjectFlow: `${CONTEXT_URL}/mobius/project_flow/by_id`,
  getModelsByPipline: `${CONTEXT_URL}/mobius/models/list_by_pipeline_id`,
  createProject: `${CONTEXT_URL}/aws/rekog/create_project`,
  startTraining: `${CONTEXT_URL}/aws/rekog/start_training`,
  createDOCClassifier: `${CONTEXT_URL}/aws/comprehend/create_document_classifier`,
  createEntityRecognizer: `${CONTEXT_URL}/aws/comprehend/create_entity_recognizer`,
  getLabelDetails: `${CONTEXT_URL}/label_studio/get_project_description`,
  exportAnnotations: `${CONTEXT_URL}/label_studio/export_annotations`,
  transformAnnotations: `${CONTEXT_URL}/label_studio/transform_annotations`,
  listAll: `${CONTEXT_URL}/mobius/cloud_config/list_all`,
  getCloudConfig: `${CONTEXT_URL}/mobius/cloud_config/get_config`,
  getGCPDataset: `${CONTEXT_URL}/mobius/dataset/list_by_pipeline_id`,
  objClassificationDataset:`${CONTEXT_URL}/gcp/automl/create_object_detection_dataset`,
  imgClassificationDataset:`${CONTEXT_URL}/gcp/automl/create_image_classification_dataset `,
  textClassificationDataset: `${CONTEXT_URL}/gcp/automl/create_text_classification_dataset`,
  nerDataset:`${CONTEXT_URL}/gcp/automl/create_ner_dataset`,
  importDataset: `${CONTEXT_URL}/gcp/automl/import_dataset`,
  deleteDataset: `${CONTEXT_URL}/gcp/automl/delete_dataset`,
  getDeployModelRekog: `${CONTEXT_URL}/aws/rekog/deploy_model`,
  getDeleteModelRekog: `${CONTEXT_URL}/aws/rekog/delete_model`,
  getUnDeployModelRekog: `${CONTEXT_URL}/aws/rekog/undeploy_model`,
  getDeployModelComprehend: `${CONTEXT_URL}/aws/comprehend/deploy_model`,
  getUnDeployModelComprehend: `${CONTEXT_URL}/aws/comprehend/undeploy_model`,
  getDeleteDocClassifier: `${CONTEXT_URL}/aws/comprehend/delete_document_classifier`,
  getDeleteEntityRecognizer: `${CONTEXT_URL}/aws/comprehend/delete_entity_recognizer`,
  gcpTextTrainModel: `${CONTEXT_URL}/gcp/automl/train_text_classification_model`,
  gcpImgTrainModel: `${CONTEXT_URL}/gcp/automl/train_image_classification_model`,
  gcpObjTrainModel: `${CONTEXT_URL}/gcp/automl/train_object_detection_model`,
  gcpNerTrainModel :`${CONTEXT_URL}/gcp/automl/train_ner_model`,
  gcpDeployModel: `${CONTEXT_URL}/gcp/automl/deploy_model`,
  gcpUndeployModel: `${CONTEXT_URL}/gcp/automl/undeploy_model`,
  gcpDeleteModel :`${CONTEXT_URL}/gcp/automl/delete_model`,
  awsRecokprojectdetails: `${CONTEXT_URL}/aws/rekog/get_project_description`,
  awsRecokprojectByID: `${CONTEXT_URL}/aws/rekog/get_projects_by_pipeline`,
  downloadConfig :`${CONTEXT_URL}/label_studio/download_configs`,
  assistiveLabel : `${CONTEXT_URL}/label_studio/trigger_assisted_labeling`,
  getExportConfig : `${CONTEXT_URL}/mobius/export_config/list_all`,
  getImportConfig : `${CONTEXT_URL}/mobius/import_config/list_all`,
  modelMonitor : `${CONTEXT_URL}/mobius/models/get_model_details`,
  inferenceText: `${CONTEXT_URL}/mobius/test_model/comprehend`,
  inferenceImg:`${CONTEXT_URL}/mobius/test_model/rekognition`
};


import React, { useState, useEffect } from "react";
import "./PipelineDetailsPage.scss";
import PipelineDetails from "../../components/pipeline-details/PipelineDetails";
import PageTitleBanner from "../../components/page-title-banner-component/PageTitleBanner";
import { useDispatch, useSelector } from "react-redux";
import {
  getPipelinesFlow,
} from "../../actions/pipelineListingAction";
import Button from "../../components/button-component/Button";
import TrainModel from "../../components/train-model/TrainModel";
import Footer from '../../components/footer-component/Footer.jsx'

const PipelineDetailsPage = (props) => {
  const pipelineDetails = useSelector((state) => {
    return state.pipeLineDetails;
  });
  const [modalState, setModalState] = useState(false);
  const stage = pipelineDetails?.stage;
  const pipelinePlatform = useSelector((state) => {
    return state?.pipeLineDetails.platform;
  });

  const projectDetails = useSelector(
    (state) => state.pipelineListing?.pipelineFlow
  );

  const transformAnnotationUri = useSelector((state) => {
    return state?.labelStudioDetails?.transformAnnotationUri;
  }) || projectDetails?.transform_annotation_uri;
  
  const [disable, setDisable] = useState(false);

  const dispatch = useDispatch();
  const storageInfo = useSelector((state) => {
    return state.s3StorageConfiguration?.storage.storages;
  });

  const [labelFilePath, setLabelFilePath] = useState("");


  useEffect(() => {
    const location = +props.history.location.pathname.split("/").pop();
    const params = {
      pipeline_id: location,
    };
    dispatch(getPipelinesFlow(params));
  }, []);

  useEffect(() => {
    transformAnnotationUri && setLabelFilePath(transformAnnotationUri);
  }, []);

  useEffect(() => {
    const ifDisable = storageInfo?.find(
      (item) => !item.last_sync || item.last_sync === ""
    );
    if (ifDisable) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [storageInfo]);

  function getComponent() {
    const component =
      pipelineDetails?.stepperData[pipelinePlatform]?.[stage - 1]?.Component;
    if (component) {
      return React.createElement(component, props);
    }
    return;
  }

  function createModel() {
    setModalState(true);
  }

  const handleModalClose = () => {
    setModalState(false);
  }
  return (
    <div className="pipeline-details-page">
      <PageTitleBanner
        leftSideProp={<div>Pipeline Details</div>}
        rightSideProp={
          <Button
            onClick={() => createModel()}
            className="page-header-create-btn"
            disabled = {!(transformAnnotationUri)}
          >
            Create New Model
          </Button>
        }
      />
      <div className="pipeline-details-page-container">
        <PipelineDetails
          pipelineName={projectDetails.pipeline_name}
          pipelineDesc={projectDetails.use_case}
          disable={disable}
          platform={projectDetails.cloud_service_provider}
          {...props}
        >
          {getComponent()}
        </PipelineDetails>
      </div>
   <TrainModel modalState = {modalState} handleModalClose= {handleModalClose}/>
   <Footer/>
    </div>
  );
};
export default PipelineDetailsPage;

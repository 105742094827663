import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModelData, getProjectByID } from "../../actions/manageModelAction";
import Modal from "../modal-component/Modal";
import DataTable from "../datatable-component/DataTable";
import { assistiveHeadings } from "../../constants/manage-model-constants";
import "./AssistiveLabeling.scss";
import { assistiveAnnotations } from "../../actions/labelPageActions";
import Button from "../button-component/Button";
import types from "../../types/pipelineListingTypes";

function AssistiveLabeling(props) {
  const dispatch = useDispatch();
  const pipeLineId = useSelector(
    (state) => state.pipelineListing?.pipelineFlow?.pipeline_id
  );
  const pipelineData = useSelector((state) => {
    return state.manageModel?.manageModelsData.filter(
      (value) => value?.status === "Deployed"
    );
  });
  const [selectedRowId, setSelectedRowId] = useState("");
  const initiatePage = () => {
    dispatch(
      getProjectByID({
        pipeline_id: pipeLineId,
      })
    ).then((res) => dispatch(getModelData(pipeLineId)));
  };
  const onRowClick = (data) => {
    if (!data.checkboxDisabled) {
      setSelectedRowId(data.uuid);
    }
  };

  useEffect(() => {
    initiatePage();
  }, []);

  const handleReset = () => {
    props.setAssistiveModel(false);
  };

  const startAssistiveLabeling = () => {
    const selectedData = pipelineData.find(
      (value) => value.uuid === selectedRowId
    );
    const data = {
      model_id: selectedData.model_deployment_data[0]?.deployment_endpoint,
      pipeline_id: pipeLineId,
    };

    dispatch(assistiveAnnotations(data))
      .then((_) => {
        handleReset();
        dispatch({
          type: types.POPUP_TRIGGER,
          data: {
            message: "Assistive Labeling Started",
            type: "success",
          },
        });
      })
      .catch((_) =>
        dispatch({
          type: types.POPUP_TRIGGER,
          data: {
            message: "Unexpected Error Occured",
            type: "error",
          },
        })
      );
  };
  return (
    <div className="assistive-label-container">
      <Modal
        title="Start Assistive Labelling"
        modalState={props.assistiveModel}
        onClose={() => handleReset()}
        className="assitive-modal-wrapper"
      >
        {pipelineData.length ? (
          <div className="assistive-labeling-wrapper">
            <h2 className="assistive-labeling-title">
              Select a Deployed Model
            </h2>
            <div className="assistive-table-wrapper">
              <DataTable
                onRowClick={onRowClick}
                columnHeadings={assistiveHeadings}
                data={pipelineData}
                rowClassName="dashboard-pipeline-table-row"
                rowKey="pipeline_id"
                checkedRowId={selectedRowId}
              />
            </div>

            <div className="assistive-modal-footer">
              <Button
                onClick={() => startAssistiveLabeling()}
                className="assistive-label-button"
                disabled={selectedRowId === ""}
              >
                Start Now
              </Button>
            </div>
          </div>
        ) : (
          <div className="no-deployment-wrapper">
            {" "}
            <span className="no-deployment-text">No Models are deployed</span>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default AssistiveLabeling;

import React from "react";
import warningIcon from "../../img/warningIcon.svg";

const UndeployModalBody = (props) => {
  return (
    <div className="undeploy-modal-body">
      <img src={warningIcon} alt="undeploy icon" />
      <div className="body-text">
        Once the model is stopped, you won't be able to get predictions from it.
        This will take upto 5 minutes.
      </div>
    </div>
  );
};

export default UndeployModalBody;

import types from "../types/pipelineListingTypes";
import {
  API_URL,
  RequestMethod,
  defaultHeader,
} from "../utils/config/api.config";

const addInferenceData = (data) => ({
    type: types.GET_INFERENCE_DATA,
    data,
  });

 
  export const getInferenceDataText = (params) => async (dispatch) => {
    try {
      const response = await fetch(
        API_URL.inferenceText + "?" + new URLSearchParams(params),
        {
          method: RequestMethod.GET,
          headers: defaultHeader(),
        }
      )
      const res = await response.json();
      dispatch(addInferenceData(res))
    } catch (err) {
      console.log("ERROR", err);
    }
  };

export const  getInferenceDataImg = (params)=> async(dispatch)=>{
  try {
    const response = await fetch(
      API_URL.inferenceImg,
      {
        method: RequestMethod.POST,
        headers: defaultHeader(),
        body: JSON.stringify(params),

      }
    )
    const res = await response.json();
    dispatch(addInferenceData(res))
  } catch (err) {
    console.log("ERROR", err);
  }
}
/* eslint-disable import/no-anonymous-default-export */
export default {
	SET_PIPELINE_LIST: "SET_PIPELINE_LIST",
	SET_USER: "SET_USER",
	REGISTER_USER: "REGISTER_USER",
	NEW_PIPELINE: "NEW_PIPELINE",
	GET_MODELS_MANAGE: "GET_MODELS_MANAGE",
	SELECT_MANAGE_MODEL: "SELECT_MANAGE_MODEL",
	LIST_STORAGE: "LIST_STORAGE",
	CREATE_STORAGE: "CREATE_STORAGE",
	SYNC_STORAGE: "SYNC_STORAGE",
	DELETE_STORAGE: "DELETE_STORAGE",
	PURGE_DATA: "PURGE_DATA",
	SET_PIPELINE_FLOW: "SET_PIPELINE_FLOW",
	SET_STAGE: "SET_STAGE",
	RESET_DATA: "RESET_DATA",
	SET_CREATE_PROJECT_DATA: "SET_CREATE_PROJECT_DATA",
	SET_PROJECT_DESCRIPTION: "SET_PROJECT_DESCRIPTION",
	SET_DEVICE: "SET_DEVICE",
	EXPORT_ANNOTATION: "EXPORT_ANNOTATION",
	TRANSFORM_ANNOTATION: "TRANSFORM_ANNOTATION",
	UPDATE_OUTPUT_BUCKET: "UPDATE_OUTPUT_BUCKET",
	SET_CONFIG_DATA: "SET_CONFIG_DATA",
	SET_CLOUD_CONFIG_DATA: "SET_CLOUD_CONFIG_DATA",
	SET_GCP_DATASET: "SET_GCP_DATASET",
	GET_OBJCLASSIFICATION_DATASET: "GET_OBJCLASSIFICATION_DATASET",
	GET_IMGCLASSIFICATION_DATASET: "GET_IMGCLASSIFICATION_DATASET",
	GET_TXTCLASSIFICATION_DATASET: "GET_TEXTCLASSIFICATION_DATASET",
	IMPORT_DATA: "IMPORT_DATA",
	DELETE_DATASET: "DELETE_DATASET",
	CREATE_GCP_STORAGE: "CREATE_GCP_STORAGE",
	SYNC_GCP_STORAGE: "SYNC_GCP_STORAGE",
	DELETE_GCP_STORAGE: "DELETE_GCP_STORAGE",
	TRAIN_GCP_TEXT: "TRAIN_GCP_TEXT",
	TRAIN_GCP_IMG: "TRAIN_GCP_IMG",
	TRAIN_GCP_OBJ: "TRAIN_GCP_OBJ",
	TRAIN_GCP_NER: "TRAIN_GCP_NER",
	AWS_PROJECT_DESCRIPTION: " AWS_PROJECT_DESCRIPTION",
	AWS_PROJECT_BY_ID: "AWS_PROJECT_BY_ID",
	DELETE_PIPELINE: "DELETE_PIPELINE",
	POPUP_TRIGGER: "POPUP_TRIGGER",
	POPUP_RESET: "POPUP_RESET",	
	SET_LABEL_DATA: "SET_LABEL_DATA",
	RESET_LABEL_DATA: "RESET_LABEL_DATA",
	UPLOAD_FILE: "UPLOAD_FILE",
	GET_NER_DATASET: "GET_NER_DATASET",
	GET_MODEL_MONITOR: "GET_MODEL_MONITOR",
	GET_INFERENCE_DATA:"GET_INFERENCE_DATA",
	DELETE_ALL_TASKS:"DELETE_ALL_TASKS"
};

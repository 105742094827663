import React, { useEffect, useState } from "react";
import "./CheckBox.scss";

function CheckBox(props) {
  const { checked, option, onChange, _labelClassName=""} = props;
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const onHandleChange = (event) => {
    if (onChange) {
      onChange(event);
    } else {
      setIsChecked(!checked);
    }
  };

  return (
    <div className="mobius-checkbox">
      <label class="checkbox-button">
        <input
          type="checkbox"
          class="checkbox-button__input"
          name={option}
          checked={isChecked}
          onChange={onHandleChange}
        />
        <span className="checkbox-button__control"></span>
        <span className={`checkbox-button__label ${_labelClassName}`}>{option}</span>
      </label>
    </div>
  );
}

export default CheckBox;

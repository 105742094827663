import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./Input.module.scss";
import searchIcon from "../../img/search.svg";
import eyeOpen from "../../img/eye.svg";
import eyeClose from "../../img/eye-hidden.svg";

const emailRegex =
	/^\w+([!#$%&'*+\-./=?^_`{|}~]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
const urlRegex =
	/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/g;

const passwordRegex =
	/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

class Input extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			validationError: props.error || false,
			eyeStatus: true,
			message: "",
		};
	}
	handleValidation = (validationError, message) => {
		this.setState({ validationError, message });
	};
	resetError = () => {
		this.setState({ validationError: false, message: "" });
	};

	handleOnBlur = (event) => {
		const { value } = event.target;
		const { onBlur, required } = this.props;
		const { error } = this.state;
		if (required && value.trim().length === 0) {
			this.handleValidation(true, "This field is required");
		}
		onBlur && onBlur({ value: value, error, event });
	};

	handleOnChange = (event) => {
		const { value } = event.target;
		const { onChange, required } = this.props;
		const { error } = this.state;

		if (required && value.trim().length === 0) {
			this.handleValidation(true, "This field is required");
		} else {
			this.resetError();
		}
		switch (event.target.type) {
			case "email":
				value === "" || emailRegex.test(value)
					? this.resetError()
					: this.handleValidation(true, "Invalid email format");
				break;
			case "url":
				value === "" || urlRegex.test(value)
					? this.resetError()
					: this.handleValidation(true, "Invalid URL format");
				break;
			case "password":
				value === "" || passwordRegex.test(value)
					? this.resetError()
					: this.handleValidation(false, "");
				break;
			default:
				break;
		}
		onChange && onChange({ value: value, error, event });
	};

	handleFocus = (event) => {
		const { onFocus, value } = this.props;
		const { error } = this.state;

		// To fix the issue with cursor at beginning
		if (value) {
			event.target.value = "";
			event.target.value = value;
		}

		onFocus && onFocus({ value: value, error, event });
	};

	handleKeyDown = (event) => {
		const { onKeyDown } = this.props;
		const { value } = event.target;
		const { error } = this.state;

		onKeyDown && onKeyDown({ value: value, error, event });
	};

	handleSubmit = (event) => {
		const { onSubmit } = this.props;
		const { value } = event.target;
		const { error } = this.state;

		onSubmit && onSubmit({ value: value, error, event });
	};
	handlePasswordIconToggle = () => {
		this.setState({ eyeStatus: !this.state.eyeStatus });
	};

	render() {
		const {
			children,
			disabled,
			className,
			inputClassName,
			labelClassName,
			type,
			label,
			placeholder,
			multi,
			maxlength,
			autoFocus,
			value,
			error,
			warning,
			success,
			name,
			message,
			theme,
			required
		} = this.props;

		const _className = cx(styles.container, className);

		const _inputClassName = cx(
			{
				[styles.input]: !multi,
				[styles.textarea]: multi,
				[styles.hasValidationError]: this.state.message.length > 0,
				[styles.hasError]: error,
				[styles.hasWarning]: warning,
				[styles.hasSuccess]: success,
				[styles.inputDark]: theme === "dark",
				[styles.textareaDark]: theme === "dark",
			},
			inputClassName
		);
		const _labelContainer = cx(styles.labelContainer);
		const _labelClassName = cx(
			{
				[styles.label]: theme !== "dark",
				[styles.labelDark]: theme === "dark",
			},
			labelClassName
		);
		const _searchContainer = cx(styles.searchContainer);
		const _passwordContainer = cx(styles.passwordContainer);
		const _message = cx(styles.message, {
			[styles.hasValidationError]: this.state.message.length > 0,
			[styles.error]: error,
			[styles.warning]: warning,
			[styles.success]: success,
		});
		let _props = {
			autoFocus,
			disabled,
			placeholder,
			value,
			name,
			maxlength,
			message,
			theme,
			required,
			className: _inputClassName,
			onChange: this.handleOnChange,
		   onFocus: this.handleFocus,
			onBlur: this.handleOnBlur,
			onKeyDown: this.handleKeyDown,
			onSubmit: this.handleSubmit,
		};
		if (type === "search") {
			return (
				<div className={_className}>
					<div className={_labelContainer}>
						{label && <label className={_labelClassName}>{label}</label>}
						{children}
					</div>
					<div className={_searchContainer}>
						<img src={searchIcon} alt='search icon' />
						<input {..._props} type={type} />
					</div>

					{error || warning || success ? (
						<span className={_message}>{message}</span>
					) : (
						<span className={_message}>{this.state.message}</span>
					)}
				</div>
			);
		} else if (type === "password") {
			return (
				<div className={_className}>
					<div className={_labelContainer}>
						{label && <label className={_labelClassName}>{label}</label>}
						{children}
					</div>
					<div className={_passwordContainer}>
						<input {..._props} type={this.state.eyeStatus ? type : "text"} />
						<img
							src={this.state.eyeStatus ? eyeOpen : eyeClose}
							alt='eye'
							onClick={() => this.handlePasswordIconToggle()}
						/>
					</div>

					{error || warning || success ? (
						<span className={_message}>{message}</span>
					) : (
						<span className={_message}>{this.state.message}</span>
					)}
				</div>
			);
		} else {
			return (
				<div className={_className}>
					<div className={_labelContainer}>
						{label && <label className={_labelClassName}>{label}</label>}
						{children}
					</div>
					{multi ? (
						<textarea {..._props}></textarea>
					) : (
						<input {..._props} type={type} />
					)}

{error || warning || success ? (
						<span className={_message}>{message}</span>
					) : (
						<span className={_message}>{this.state.message}</span>
					)}
				
				</div>
			);
		}
	}
}

Input.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.oneOf([
		"text",
		"number",
		"password",
		"email",
		"tel",
		"url",
		"search",
	]).isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	message: PropTypes.string,
	required: PropTypes.bool,
	maxlength: PropTypes.number,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onKeyDown: PropTypes.func,
	onSubmit: PropTypes.func,
	/* Will be applied to container */
	className: PropTypes.string,
	/* Will be applied to underlying input/textarea tag */
	inputClassName: PropTypes.string,
	/* Will be applied to label */
	labelClassName: PropTypes.string,
	/* Renders a textarea if true */
	multi: PropTypes.bool,
	/* Value */
	value: PropTypes.string,
	error: PropTypes.bool,
	warning: PropTypes.bool,
	success: PropTypes.bool,
};

Input.defaultProps = {
	className: "",
	message: "",
	inputClassName: "",
	labelClassName: "",
	type: "text",
	label: "",
	theme: "light",
	placeholder: "",
	multi: false,
	error: false,
};

export default Input;

import React from "react";
import types from "../../types/pipelineListingTypes";
import "./Stepper.scss";
import { useDispatch, useSelector } from "react-redux";
const Stepper = (props) => {
  const { stepperData } = props;
  const dispatch = useDispatch();

  const { transform_annotation_uri } = useSelector((state) => {
    return state?.pipelineListing?.pipelineFlow;
  });
  const transformAnnotationUri =
    useSelector((state) => {
      return state?.labelStudioDetails?.transformAnnotationUri;
    }) || transform_annotation_uri;

  const handleStepClick = (item) => {
    if (item.isComplete || transformAnnotationUri) {
      dispatch({
        type: types.SET_STAGE,
        data: {
          stage: item?.id,
          platform: "",
        },
      });
    }
  };
  return (
    <div className="stepper-outer">
      <div className="stepper-container">
        {stepperData &&
          stepperData?.map((item) => {
            return (
              <div
                className={`stepper-item ${item?.isActive ? "selected" : ""}`}
                key={item?.id}
                onClick={() => handleStepClick(item)}
              >
                <div className="stepper-item-data">
                  <span className="stepper-item-desc">{item?.description}</span>
                </div>
              </div>
            );
          })}
        <div className="stepper-item"></div>
      </div>
    </div>
  );
};
export default Stepper;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import popupSuccess from "../../img/popup-success.svg";
import popupWarning from "../../img/popup-warning.svg";
import popupError from "../../img/popup-error.svg";
import closeWhite from "../../img/close-white.svg";
import { resetPopup } from "../../actions/popupAction.js";
import "./popup.scss";

const Popup = (props) => {
  let visible = useSelector((state) => state.popup.visible);
  let popupType = useSelector((state) => state.popup.type);
  let message = useSelector((state) => state.popup.message);

  useEffect(() => {
    const timeId = setTimeout(() => {
      dispatch(resetPopup());
    }, 4000);

    return () => {
      clearTimeout(timeId);
    };
  }, [visible]);
  const popupIcon = (popupType) => {
    switch (popupType) {
      case "success":
        return <img src={popupSuccess} alt="successIcon" />;
      case "warning":
        return <img src={popupWarning} alt="warningIcon" />;
      case "error":
        return <img src={popupError} alt="errorIcon" />;
      default:
        return;
    }
  };

  const dispatch = useDispatch();
  if (visible) {
    return (
      <div className={`popup-container type-${popupType}`}>
        {popupIcon(popupType)}
        <span>{message}</span>
        <img
          onClick={() => dispatch(resetPopup())}
          className="close-icon"
          src={closeWhite}
          alt="closeIcon"
        />
      </div>
    );
  }
  return null;
};

export default Popup;

import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
	const userInfo =
		useSelector((state) => state?.loginUser?.user) ||
		sessionStorage.getItem("access_token");

	const getTokenFromSession = () => {
		return (
			sessionStorage.getItem("access_token")
		);
	};


	return (
		<Route
			{...rest}
			render={(props) => {
				if (
					userInfo.access_token ||
					getTokenFromSession()
				) {
					return <Component {...rest} {...props} />;
				} else {
					return (
						<Redirect
							to={{
								pathname: "/",
								state: {
									from: props.location,
								},
							}}
						/>
					);
				}
			}}
		/>
	);
};

import {
  API_URL,
  defaultHeader,
  RequestMethod,
} from "../utils/config/api.config";

export const postPipeline = (params) => async(dispatch) => {
  return new Promise(function(resolve, reject){
     fetch(API_URL.newPipeline, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    }).then(res=>{
      if(res.status===200){
        resolve(res.json());     
      }else{
        reject(res.json());
      }
  }).catch (err=> {
      reject(err)
  })
  })
    
};

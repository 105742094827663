import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import arrow from "../../img/pagination-arrow.svg";
import doubleArrow from "../../img/pagination-double-arrow.svg";
import awsLogo from "../../img/aws.svg";
import gcpLogo from "../../img/gcp.svg";
import _ from "lodash";
import "./DataTable.scss";

const pageSize = 10;
function DataTable({
  data = [],
  columnHeadings = [],
  onRowClick,
  onCheckboxChange,
  rowClassName,
  rowKey = "",
  checkedRowId = "",
  isFilter = false,
}) {
  const [tableData, setTableData] = useState(data);
  const [paginatedData, setPaginatedData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState("");
  const [sortType, setSortType] = useState("name-asc");
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    setTableData(data);
    setPaginatedData(_(data).slice(0).take(pageSize).value());
  }, [data]);

  const records = tableData?.length;
  const pageCount = tableData ? Math.ceil(records / pageSize) : 0;
  const pages = _.range(1, pageCount + 1);

  const pageChange = (pageNo) => {
    setCurrentPage(pageNo);
    const startIndex = (pageNo - 1) * pageSize;
    const paginationData = _(tableData)
      .slice(startIndex)
      .take(pageSize)
      .value();
    setPaginatedData(paginationData);
  };

  const formatData = (data, entry) => {
    let value = data[entry.columnDataKey];
    if (entry.dataFormatting) {
      value = entry.dataFormatting(data[entry.columnDataKey]);
    }
    if (entry.type === "html") {
      value = ReactHtmlParser(
        `<span class="status-${data[entry.columnDataKey]}">${
          data[entry.columnDataKey]
        }</span>`
      );
    }
    return value;
  };

  const searchFilterFunc = (data) =>
    data?.pipeline_name?.toLowerCase().includes(searchFilter?.toLowerCase());

  const sortDataFunc = (a, b) => {
    switch (sortType) {
      case "name-asc":
        if (a.pipeline_name > b.pipeline_name) {
          return 1;
        } else {
          return -1;
        }
      case "name-desc":
        if (b.pipeline_name > a.pipeline_name) {
          return 1;
        } else {
          return -1;
        }
      case "updated-asc":
        if (new Date(a.updated_at) > new Date(b.updated_at)) {
          return 1;
        } else {
          return -1;
        }
      case "updated-desc":
        if (new Date(b.updated_at) > new Date(a.updated_at)) {
          return 1;
        } else {
          return -1;
        }
      default:
        break;
    }
  };

  const handleChange = (event) => {
    setSearchFilter(event.target.value);
    if(event.target.value.length > 0){
      setIsSearch(true)
    } else setIsSearch(false)
  }

  const handleSort = (event) => {
    setSortType(event.target.value)
    let sortedData = tableData?.sort(sortDataFunc)
    setPaginatedData(_(sortedData).slice(0).take(pageSize).value())
  }

  // console.log(paginatedData, tableData);
  return (
    <div>
      {!paginatedData ? (
        "No Data Found"
      ) : (
        <>
          {isFilter && (
            <div class="search-sort-container">
              <div class="search-container">
                <label for="search-pipeline"></label>
                <input
                  id="search-pipeline"
                  name="search-pipeline"
                  placeholder="Search Pipeline"
                  value={searchFilter}
                  onChange={handleChange}
                />
              </div>
              <div class="sort-container">
                <label for="sort-select">Sort By: </label>
                <select
                  id="sort-select"
                  name="sort-select"
                  onChange={handleSort}
                >
                  <option value="updated-asc">Updated At ↑</option>
                  <option value="updated-desc">Updated At ↓</option>
                  <option value="name-asc" selected>
                    Name ↑
                  </option>
                  <option value="name-desc">Name ↓</option>
                </select>
              </div>
            </div>
          )}
          <table className="mobius-table">
            <thead className="mobius-table-header">
              <tr className="mobius-table-headings">
                {columnHeadings?.map((heading, index) => (
                  <th key={index}>{heading.columnName}</th>
                ))}
              </tr>
            </thead>
            <tbody className="mobius-table-body">
              {(isSearch
                ? tableData?.filter(searchFilterFunc)?.sort(sortDataFunc)
                : paginatedData
              ).map((data, index) => (
                <tr
                  className={`${rowClassName} ${
                    data.status ? data.status : ""
                  }`}
                  key={index}
                  tabIndex="0"
                >
                  {columnHeadings.map((entry, entryIndex) => {
                    if (entry.columnDataKey === "image") {
                      if (data.cloud_service_provider === "aws") {
                        return (
                          <td
                            key={entryIndex}
                            onClick={() => onRowClick && onRowClick(data)}
                          >
                            <img className="logo" src={awsLogo} alt="aws" />
                          </td>
                        );
                      } else if (data.cloud_service_provider === "gcp") {
                        return (
                          <td
                            key={entryIndex}
                            onClick={() => onRowClick && onRowClick(data)}
                          >
                            <img className="logo" src={gcpLogo} alt="aws" />
                          </td>
                        );
                      }
                    } else if (entry.columnDataKey === "checked") {
                      return (
                        <td key={entryIndex} className="checkbox-container">
                          <input
                            type="radio"
                            onChange={() => {
                              onCheckboxChange(data);
                            }}
                            checked={checkedRowId === data[entry.columnDataKey]}
                            disabled={data["checkboxDisabled"]}
                          />
                          <span className="checkmark"></span>
                        </td>
                      );
                    } else if (entry.columnDataKey === "CTA") {
                      return (
                        <td key={entryIndex} className="cta-container-wrapper">
                          {entry.cta(data)}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          key={entryIndex}
                          className={
                            entry.columnDataKey === "status"
                              ? entry.className
                              : ""
                          }
                          onClick={() => onRowClick && onRowClick(data)}
                        >
                          {formatData(data, entry)}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
            </tbody>
            <tfoot className="mobius-table-footer"></tfoot>
          </table>
        </>
      )}
      {pageCount > 1 && !isSearch && (
        <nav className="pagination-nav">
          <span className="pagination-info">{`Results ${currentPage}-${pageCount} of ${records} pipelines`}</span>
          <ul className="pagination-container">
            <button
              className="page-link first"
              onClick={() => pageChange(1)}
              disabled={currentPage === 1}
            >
              <img src={doubleArrow} alt="first" />
            </button>
            <button
              className="page-link previous"
              onClick={() => pageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src={arrow} alt="previous" />
            </button>
            {pages.map((page) => (
              <li
                className={
                  page === currentPage ? "page-link active" : "page-link"
                }
              >
                <p onClick={() => pageChange(page)}>{page}</p>
              </li>
            ))}
            <button
              className="page-link next"
              onClick={() => pageChange(currentPage + 1)}
              disabled={currentPage === pageCount}
            >
              <img src={arrow} alt="next" />
            </button>
            <button
              className="page-link last"
              onClick={() => pageChange(pageCount)}
              disabled={currentPage === pageCount}
            >
              <img src={doubleArrow} alt="last" />
            </button>
          </ul>
        </nav>
      )}
    </div>
  );
}

export default DataTable;

import dateFormat from "dateformat";

export const columnHeadings = [
    {
      columnName: "",
      columnDataKey: "checked",
    },
    {
      columnName: "Version",
      columnDataKey: "version",
    },
    {
      columnName: "Model ID",
      columnDataKey: "uuid",
    },
    { 
      columnName: "Modified Date",
      columnDataKey: "modifiedDate",
      dataFormatting(value) {
        return dateFormat(new Date(value), "dd/mm/yyyy");
      },
    },
    {
      columnName: "F1 Score",
      columnDataKey: "f1Score",
    },
    {
      columnName: "Precision",
      columnDataKey: "precision",
    },
    {
      columnName: "Recall",
      columnDataKey: "recall",
    },
    {
      columnName: "",
      columnDataKey: "status",
      type: "html",
      className: "align-top-right",
    },
  ];

  export const assistiveHeadings = [
    {
      columnName: "",
      columnDataKey: "checked",
    },
    {
      columnName: "Version",
      columnDataKey: "version",
    },
    {
      columnName: "F1 Score",
      columnDataKey: "f1Score",
    },
    {
      columnName: "Precesion",
      columnDataKey: "precision",
    },
    {
      columnName: "Recall",
      columnDataKey: "recall",
    },
  ];
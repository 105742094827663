import types from "../types/pipelineListingTypes";

const initialState = {
  pipelineListing: [],
  pipelineSyncInProgress: false,
  pipelineFlow: {},
  cloudConfigData: {},
  arnData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PIPELINE_LIST:
      return { ...state, pipelineListing: action.data };
    case types.DELETE_PIPELINE:
      const newPipelineListing = state.pipelineListing.filter(pipeline => pipeline.pipeline_id !== action.data)
      return { ...state, pipelineListing: newPipelineListing}
    case types.SET_PIPELINE_FLOW:
      return { ...state, pipelineFlow: action.data };
    case types.SET_CONFIG_DATA:
      return { ...state, cloudConfigData: action.data };
    case types.SET_CLOUD_CONFIG_DATA:
      return { ...state, arnData: action.data };
    default:
      return state;
  }
};

export default reducer;

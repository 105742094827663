import types from "../types/pipelineListingTypes";
import _ from "lodash";

const initialState = {
  storage: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_STORAGE:
      return { ...state, storage: action.data };
    case types.CREATE_STORAGE:
      return {
        ...state,
        storage: {
          storages: [action.data, ...state.storage.storages],
        },
      };
    case types.DELETE_STORAGE:
      let copy = [...state.storage.storages];
      _.remove(copy, (i) => i.id === action.data);
      return {
        ...state,
        storage: {
          storages: copy,
        },
      };
    case types.SYNC_STORAGE:
      let copySync = [...state.storage.storages];
      const index = _.findIndex(copySync, { id: action.data.id });
      copySync[index] = action.data;
      return {
        ...state,
        storage: {
          storages: copySync,
        },
      };
    case types.PURGE_DATA:
      return { ...state, storage: [] };

    case types.CREATE_GCP_STORAGE:
      return {
        ...state,
        storage: {
          storages: [action.data, ...state.storage.storages],
        },
      };

    case types.SYNC_GCP_STORAGE:
      let copyGCPSync = [...state.storage.storages];
      const i = _.findIndex(copyGCPSync, { id: action.data.id });
      copyGCPSync[i] = action.data;
      return {
        ...state,
        storage: {
          storages: copyGCPSync,
        },
      };

    case types.DELETE_GCP_STORAGE:
      let newCopy = [...state.storage.storages];
      _.remove(newCopy, (i) => i.id === action.data);
      return {
        ...state,
        storage: {
          storages: newCopy,
        },
      };

    default:
      return state;
  }
};

export default reducer;

import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInferenceData,
  getInferenceDataImg,
  getInferenceDataText,
} from "../../actions/modelMonitorActions";
import { API_URL, CONTEXT_URL } from "../../utils/config/api.config";
import Button from "../button-component/Button";
import FileUpload from "../file-upload/File-upload";
import Input from "../input-component/Input";
import "./InferenceUpload.scss";

function InferenceUpload({ useCase, endpointArn }) {
  const [isText, setIsText] = useState(true);
  const [text, setText] = useState("");
  const [img, setImg] = useState();
  const [submit, setSubmit] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.manageModel.modelMonitorData);
  const projectDetails = useSelector(
    (state) => state.pipelineListing?.pipelineFlow
  );
  const supportedFilesTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/tiff",
    "application/pdf",
    "audio/mpeg",
    "audio/mpeg3",
    "audio/x-mpeg",
    "audio/x-mpeg-3",
    "audio/wav",
  ];

  useEffect(() => {
    if (
      projectDetails?.use_case
        ?.toLowerCase()
        .includes("image_classification") ||
      projectDetails?.use_case?.toLowerCase().includes("object_detection")
    ) {
      setIsText(false);
    } else {
      setIsText(true);
    }
  }, [projectDetails?.use_case]);

  const setInput = ({ event }) => {
    setText(event?.target?.value);
  };

  const startInference = () => {
    const query = {
      data: text,
      endpoint_arn: endpointArn,
      pipeline_id: data?.pipeline_id,
    };

    dispatch(getInferenceDataText(query)).then(setText(""));
  };

  return (
    <div className="inference-upload-container">
      {isText ? (
        <Input
          value={text}
          name="pipelineName"
          onChange={(event) => setInput(event)}
          required
          className="prediction-input"
          labelClassName="prediction-input-label"
          multi="true"
          placeholder="Type Here"
        />
      ) : (
        <FileUpload
          setFile={setImg}
          supportedFilesTypes={supportedFilesTypes}
          handleComplete={() => setSubmit(false)}
          fileKey="file_obj"
          endpoint={
            API_URL.inferenceImg +
            "?" +
            new URLSearchParams({
              endpoint_arn: endpointArn,
              pipeline_id: data?.pipeline_id,
              confidence_threshold: 0.5,
            })
          }
          submit={submit}
          className="prediction-input-upload"
          inference={true}
          height="280px"
        />
      )}
      <div className="inference-upload-btn">
        <Button
          onClick={() => (isText ? startInference() : setSubmit(true))}
          disabled={isText ? !text : !img}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default InferenceUpload;

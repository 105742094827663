export const stageValueConfig = {
    DATASET_CREATED: "Data Source Created",
    DATASET_DELETED: "Data Source Deleted",
    IMPORT_DATASET: "Data Source Synced",
    DATA_IMPORTED: "Data Source Deleted",
    MODEL_DEPLOYING: "Model Deployed",
    MODEL_DEPLOYED: "Model Deployed",
    MODEL_UNDEPLOYED: "Model Undeployed",
    MODEL_TRAINING: "Model Training",
    MODEL_DELETED: "Model Deleted",
    MODEL_EXPORTED: "New Model Created",
    MODEL_FAILED: "Model Training Failed",
    TRAINING_COMPLETED: "New Model Created",
    TRAINING_STOPPED: "Model Training Stopped",
    PROJECT_CREATED: "Pipeline Created",
    PROJECT_DELETED: "Model Deleted",
    REKOGNITION_PROJECT_CREATED: "New Model Created",
    TRAINED: "New Model Created",
    EXPORT_ANNOTATIONS: "Labelled Data Exported",
    TRANSFORM_ANNOTATIONS: "Labelled Data Exported",
    CREATE_ANNOTATION_PROJECT: "Data Source Synced",
    TRAINING_FAILED: "Model Training Failed"
  };
import React, { useState, useEffect } from "react";
import { columnHeadings } from "../../constants/manage-model-constants";
import DataTable from "../../components/datatable-component/DataTable";
import types from "../../types/pipelineListingTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  getAWSDescription,
  getModelData,
  getProjectByID,
  postManageModelAction,
} from "../../actions/manageModelAction";
import DeleteModalBody from "./DeleteModalBody";
import DeployModalBody from "./DeployModalBody";
import UndeployModalBody from "./UndeployModalBody";
import Modal from "../../components/modal-component/Modal";
import Button from "../../components/button-component/Button";
import "./manage-model.scss";
import noModal from "../../img/create-model.svg";
import TrainModel from "../../components/train-model/TrainModel";

const ManageModel = (props) => {
  const pipeLineId = useSelector(
    (state) => state.pipelineListing?.pipelineFlow?.pipeline_id
  );
  const useCase = useSelector(
    (state) => state.pipelineListing?.pipelineFlow?.use_case
  );
  const disableDelete = useSelector(
    (state) => state.manageModel?.disableDeleteBtn
  );
  const disableDeploy = useSelector(
    (state) => state.manageModel?.disableDeployBtn
  );
  const disableUndeploy = useSelector(
    (state) => state.manageModel?.disableUndeployBtn
  );
  const pipelineData = useSelector((state) => {
    return state.manageModel?.manageModelsData;
  });

  const [modalType, setModalType] = useState("");
  const [ddvalue, setDropdownvalue] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");

  const dropdownOptions = [
    {
      id: 1,
      title: 1,
      key: 1,
    },
    {
      id: 2,
      title: 2,
      key: 2,
    },
    {
      id: 3,
      title: 3,
      key: 3,
    },
    {
      id: 4,
      title: 4,
      key: 4,
    },
  ];

  const getSelectedItem = (id) =>
    dropdownOptions.find((entry) => entry.id === id);

  const modalBody = () => {
    switch (modalType) {
      case "Delete":
        return <DeleteModalBody />;
      case "Undeploy":
        return <UndeployModalBody />;
      case "Deploy":
        return (
          <DeployModalBody
            selectedItem={ddvalue ? getSelectedItem(ddvalue).title : "Select"}
            setSelectedItem={setDropdownvalue}
            dropdownOptions={dropdownOptions}
          />
        );
      default:
        break;
    }
  };

  const modalTitle = () => {
    switch (modalType) {
      case "Delete":
        return "Are you sure you want to delete the selected models";
      case "Undeploy":
        return "Are you sure you want to undeploy the selected models";
      case "Deploy":
        return "Deploy model";
      default:
        break;
    }
  };
  const dispatch = useDispatch();

  const initiatePage = () => {
    dispatch(
      getProjectByID({
        pipeline_id: pipeLineId,
      })
    )
      .then((res) => {
        res?.data?.forEach((data) => {
          dispatch(
            getAWSDescription({
              pipeline_id: pipeLineId,
              project_arn: data.project_arn,
              version_name: data.project_name,
            })
          );
        });
      })
      .then((res) => dispatch(getModelData(pipeLineId)));
  };

  useEffect(() => {
    console.log({pipelineData});
    dispatch(
      getProjectByID({
        pipeline_id: pipeLineId,
      })
    ).then((res) => dispatch(getModelData(pipeLineId)));
  }, []);

  const onRowClick = (data) => {
    console.log('onRowClick',data.status)
    let statusCheck = data && data.status && data.status !== 'Training'
    statusCheck && props.history.push(`${pipeLineId}/${data.modelId}`);
  };
  
  const  onCheckBoxClick = (data) => {
    if (!data.checkboxDisabled) {
      setSelectedRowId(data.uuid);
      dispatch({
        type: types.SELECT_MANAGE_MODEL,
        data,
      });
    }
  };

 
  function createModel() {
    setModalState(true);
  }
  const handleModalClose = () => {
    setModalState(false);
  };

  return (
    <>
      <div className="manage-model-table-wrapper">
        {pipelineData.length ? (
          <DataTable
            onRowClick={onRowClick}
            onCheckboxChange={ onCheckBoxClick}
            columnHeadings={columnHeadings}
            data={pipelineData}
            rowClassName="dashboard-pipeline-table-row"
            rowKey="pipeline_id"
            checkedRowId={selectedRowId}
          />
        ) : (
          <div className="no-data">
            <div className="no-data-container">
              <img src={noModal} alt="no-modal" />
              <h2 className="no-data-title">Create your first model version</h2>
              <p className="no-data-sub">
                Track your model performance with different set of data{" "}
              </p>
              <Button className="no-data-create" onClick={() => createModel()}>
                Create Your First Model
              </Button>
            </div>
          </div>
        )}
      </div>
      {pipelineData.length ? (
        <div className="page-container-footer">
          <Button
            theme="ghost"
            onClick={() => setModalType("Delete")}
            className="page-container-footer-button"
            disabled={disableDelete}
          >
            Delete
          </Button>
          <Button
            theme="ghost"
            onClick={() => setModalType("Undeploy")}
            className="page-container-footer-button"
            disabled={disableUndeploy}
          >
            Un-Deploy
          </Button>
          <Button
            onClick={() => setModalType("Deploy")}
            className="page-container-footer-button"
            disabled={disableDeploy}
          >
            Deploy
          </Button>
        </div>
      ) : null}
      <Modal
        title={modalTitle()}
        modalState={modalType ? true : false}
        className="manage-model-modal-wrapper"
        onClose={() => setModalType("")}
      >
        {modalBody()}
        <div className="manage-model-modal-footer">
          <Button
            onClick={() => {
              const selectedData = pipelineData.find(
                (value) => value.checked === selectedRowId
              );
              const id = selectedData.modelId;
              const model_id = selectedData.modelId;
              const data = {
                pipeline_id: pipeLineId,
                project_version_arn: id,
                min_inference_units: ddvalue,
                model_id: model_id,
              };
              dispatch(
                postManageModelAction({
                  type: modalType,
                  useCase: useCase,
                  selectedData: selectedData,
                  params: data,
                })
              )
                .then((res) => {
                  initiatePage();
                })
                .then(setModalType(""));
            }}
            className="page-container-footer-button"
            // disabled={disable}
          >
            {modalType}
          </Button>
          <Button theme="ghost" className="page-container-footer-button">
            Cancel
          </Button>
        </div>
      </Modal>
      <TrainModel modalState={modalState} handleModalClose={handleModalClose} />
    </>
  );
};

export default ManageModel;

import { ConfigureDataSource } from "../pages/configure-data-source/ConfigureDataSource";
import { ImportDataset } from "../pages/import-dataset/ImportDataset";
import { LabelDataPage } from "../pages/label-data/LabelDataPage";
import ManageModel from "../pages/manage-model";
import types from "../types/pipelineListingTypes";

const initialState = {
  stepperData: {
    aws: [
      {
        id: 1,
        title: "Step 1",
        description: "Data Source",
        isActive: true,
        isComplete: false,
        Component: ConfigureDataSource,
        props: {},
      },
      {
        id: 2,
        title: "Step 2",
        description: "Label Data",
        isActive: false,
        isComplete: false,
        Component: LabelDataPage,
        props: {},
      },
      {
        id: 3,
        title: "Step 3",
        description: "Model Version",
        isActive: false,
        isComplete: false,
        Component:  ManageModel,
        props: {},
      },
    ],
    gcp: [
      {
        id: 1,
        title: "Step 1",
        description: "Data Source",
        isActive: true,
        isComplete: false,
        Component: ConfigureDataSource,
      },
      {
        id: 2,
        title: "Step 2",
        description: "Labelled Data",
        isActive: false,
        isComplete: false,
        Component: LabelDataPage,
      },
      {
        id: 3,
        title: "Step 3",
        description: "Dataset",
        isActive: false,
        isComplete: false,
        Component: ImportDataset,
      },
      {
        id: 4,
        title: "Step 4",
        description: "Model Versions",
        isActive: false,
        isComplete: false,
        Component: ManageModel,
      },
    ],
    azure: [
      {
        id: 1,
        title: "Step 1",
        description: "Configure Data Source",
        isActive: true,
        isComplete: false,
      },
      {
        id: 2,
        title: "Step 2",
        description: "Label Data",
        isActive: false,
        isComplete: false,
      },
      {
        id: 3,
        title: "Step 3",
        description: "Train Model",
        isActive: false,
        isComplete: false,
      },
      {
        id: 4,
        title: "Step 4",
        description: "Manage Model",
        isActive: false,
        isComplete: false,
      },
    ],
  },
  stage: null,
  platform: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_STAGE:
      if (action.data.stage > 4) {
        return state;
      }
      const platform = action.data.platform
        ? action.data.platform
        : state.platform;
      const stepperDataCopy = { ...state.stepperData };
      const stepperDataPlatorm = [...stepperDataCopy[platform]];

      stepperDataPlatorm.forEach((value) => {
        if (value.id === action.data.stage) {
          value.isActive = true;
        } else if (value.id < action.data.stage) {
          value.isComplete = true;
          value.isActive = false;
        } else {
          value.isActive = false;
        }
      });
      stepperDataCopy[platform] = stepperDataPlatorm;
      return {
        ...state,
        stepperData: stepperDataCopy,
        stage: action.data.stage,
        platform,
      };
    case types.RESET_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./InferenceOutput.scss";

function InferenceOutput({ useCase }) {
  const [output, setOutput] = useState([]);
  const inferenceData = useSelector(
    (state) => state.modelMonitor?.modelInferenceData
  );

  const findMax = (arr, limit) => {
    const temp = arr.sort((a, b) => a.Score + b.Score);
    if (temp.length >= limit) {
      return temp.splice(0, limit);
    }
    return temp;
  };

  useEffect(() => {
    if (inferenceData?.response?.length && useCase) {
      setOutput(
        useCase?.includes("multi_label")
          ? findMax(inferenceData?.response, 2)
          : findMax(inferenceData?.response, 1)
      );
    }
  }, [inferenceData, useCase]);

  const getEmptyState = (usecase) => {
    return usecase?.includes("text")
      ? "Type your data to see the predictions"
      : "Upload your data to see the predictions";
  };

  return (
    <div className="inference-output-container">
      {inferenceData?.response?.length ? (
        <div className="inference-text-container">
          {output.map((obj) => (
            <p className="inference-text-data">{obj.Name}</p>
          ))}
        </div>
      ) : (
        <p className="inference-text-no-data">{getEmptyState()}</p>
      )}
    </div>
  );
}

export default InferenceOutput;

import types from "../types/pipelineListingTypes";
import {
  API_URL,
  RequestMethod,
  defaultHeader,
} from "../utils/config/api.config";

const setCreateProjectData = (data) => ({
  type: types.SET_CREATE_PROJECT_DATA,
  data,
});

const setTextgcpData = (data) => ({
  type: types.TRAIN_GCP_TEXT,
  data,
});

const setImggcpData = (data) => ({
  type: types.TRAIN_GCP_IMG,
  data,
});

const setObjgcpData = (data) => ({
  type: types.TRAIN_GCP_OBJ,
  data,
});

const setNERgcpData = (data) => ({
  type: types.TRAIN_GCP_NER,
  data,
});

export const createImgRecogProject = (params) => (dispatch) => {
    return new Promise(function(resolve,reject){
      try {
         fetch(API_URL.createProject, {
          method: RequestMethod.POST,
          headers: defaultHeader(),
          body: JSON.stringify(params),
        })
          .then((res) => res.json())
          .then((res) => {
            dispatch(setCreateProjectData(res));
           resolve(res)
          }).catch(error=>reject(error))
      } catch (err) {
        console.log("ERROR", err);
      }
    })
  
};

export const startProjectTraining = (params) => async (dispatch) => {
  try {
     await fetch(API_URL.startTraining, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const createDocClassifier = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.createDOCClassifier, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(setCreateProjectData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const createEntityRecognizer = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.createEntityRecognizer, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(setCreateProjectData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const gcpTextTrainModel = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.gcpTextTrainModel, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(setTextgcpData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};
export const gcpImgTrainModel = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.gcpImgTrainModel, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(setImggcpData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};
export const gcpObjTrainModel = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.gcpObjTrainModel, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(setObjgcpData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const gcpNERTrainModel = (params) => async (dispatch) => {
  try {
    const response = await fetch(API_URL.gcpNerTrainModel, {
      method: RequestMethod.POST,
      headers: defaultHeader(),
      body: JSON.stringify(params),
    });
    const res = await response.json();
    dispatch(setNERgcpData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

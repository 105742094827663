import types from "../types/pipelineListingTypes";

const initialState = {
  gcpDataset: {},
  importDataset: {},
  deleteDataset: {},
  fileUpload: null,
  deleteAllTask: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_GCP_DATASET:
      return { ...state, gcpDataset: action.data };
    case types.GET_IMGCLASSIFICATION_DATASET:
      return { ...state, gcpDataset: action.data };
    case types.GET_OBJCLASSIFICATION_DATASET:
      return { ...state, gcpDataset: action.data };
    case types.GET_NER_DATASET:
      return {...state, gcpDataset:action.data}
    case types.GET_TXTCLASSIFICATION_DATASET:
      return { ...state, gcpDataset: action.data };
    case types.IMPORT_DATA:
      return { ...state, importDataset: action.data };
    case types.DELETE_DATASET:
      return { ...state, deleteDataset: action.data };
    case types.DELETE_ALL_TASKS:
      return {...state, deleteAllTask: action.data}
    case types.UPLOAD_FILE:
			return {...state, fileUpload: action.data}
    default:
      return state;
  }
};

export default reducer;

import types from "../types/pipelineListingTypes";

const initialState = {
  newPipeline: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.NEW_PIPELINE:
      return { ...state, newPipeline: action.data };
    default:
      return state;
  }
};

export default reducer;

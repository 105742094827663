import React from "react";
import InferenceOutput from "../../components/inference-output/InferenceOutput";
import InferenceUpload from "../../components/inference-upload/InferenceUpload";
import "./InferenceLayout.scss";

function InferenceLayout(props) {
  return (
    <>
      <div className="inference-layout-wrapper">
        <h1 className="inference-layout-header">Test</h1>
        <p className="inference-layout-subtext"> Enter / upload input to test model deployed</p>
        <div className="inference-layout-container">
          <div className="inference-layout-upload">
            <InferenceUpload
              useCase={props?.useCase}
              endpointArn={props.endpointArn}
            />
          </div>
          <div className="inference-layout-output">
            <h2 className="inference-layout-output-text">Prediction</h2>
            <InferenceOutput useCase={props?.useCase} />
          </div>
        </div>
      </div>
    </>
  );
}

export default InferenceLayout;

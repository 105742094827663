import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./RadioGroup.scss";

/**
 *	Radio-Group with two types of radio buttons.
 *	1. with image, svg as a option  e.g. [{value: 'azure', image: <image, svg>}]
 *	2. with label as a option  e.g. [{label: 'azure', image: 'azure}]
 */
class RadioGroup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: this.props.selected || "",
		};
	}

	setSelected = (event) => {
		const { value } = event.target;
		const { onSelect } = this.props;
		this.setState({ selected: value });
		if (onSelect) {
			onSelect({ value, event });
		}
	};

	render() {
		const {
			children,
			containerClassName,
			labelClassName,
			imageClassName,
			optionsContainerClassName,
			label,
			error,
			warning,
			success,
			name,
			message,
			selected, // pre-selected option
		} = this.props;

		const _className = cx("radio-group-container", containerClassName);
		const _labelClassName = cx("label", labelClassName);
		const _imageClassName = cx("image", imageClassName);
		const _optionsContainerClassName = cx(
			"options-container",
			optionsContainerClassName
		);

		const _message = cx("message", {
			"message error": error,
			"message warning": warning,
			"message success": success,
		});
		let _props = {
			name,
			message,
			onSelect: this.setSelected,
		};
		return (
			<div className={_className}>
				<div className='label-container'>
					{label && <label className={_labelClassName}>{label}</label>}
					{children}
				</div>
				<div className={_optionsContainerClassName}>
					{this.props?.options?.map((option, i) => {
						if (option.image) {
							return (
								<label key={`option__${i}`}>
									<input
										type='radio'
										name={name}
										value={option.value}
										{..._props}
										defaultChecked={selected === option.value}
										onChange={this.setSelected}
									/>
									<div className='radioImg'>
										<span>{option.label}</span>
										<img
											className={_imageClassName}
											src={option.image}
											alt='option'
										/>
									</div>
								</label>
							);
						}
						return (
							<div className='radioLabel' key={`option__${i}`}>
								<input
									htmlFor={`option__${i}`}
									value={option.value}
									name={option.name}
									{..._props}
									type='radio'
									defaultChecked={selected === option.value}
									onChange={this.setSelected}
								/>
								<label>{option.value}</label>
							</div>
						);
					})}
				</div>
				{error || warning || success ? (
					<span className={_message}>{message}</span>
				) : (
					<span className={_message}>{this.state.message}</span>
				)}
			</div>
		);
	}
}

RadioGroup.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	message: PropTypes.string,
	onSelect: PropTypes.func,
	containerClassName: PropTypes.string,
	labelClassName: PropTypes.string,
	imageClassName: PropTypes.string,
	optionsContainerClassName: PropTypes.string,
	options: PropTypes.arrayOf(Object).isRequired,
	error: PropTypes.bool,
	warning: PropTypes.bool,
	success: PropTypes.bool,
	selected: PropTypes.string,
};
export default RadioGroup;

import React, { useState } from "react";
import Button from "../../components/button-component/Button";
import Input from "../../components/input-component/Input";
import "./SignUpPage.scss";
import { registerUser } from "../../actions/loginAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Footer from '../../components/footer-component/Footer.jsx'

const SignUpPage = (props) => {
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
   const userInfo = useSelector((state) => state?.loginUser?.user);
  const dispatch = useDispatch();

  if (userInfo.access_token || sessionStorage.getItem("access_token")) {
    return <Redirect to={"/pipelines"}/>
  }
  const onChangeName = ({ event }) => {
    setFullName(event.target.value);
  };

  const onChangeEmail = ({ event }) => {
    setEmail(event.target.value);
  };

  const onChangePassword = ({ event }) => {
    setPassword(event.target.value);
  };

  const registerUserData = () => {
    const userData={
        full_name: fullName,
        user_name: email,
        password: password
    }
    dispatch(registerUser(userData));
  };

  if (userInfo?.access_token) {
    sessionStorage.setItem("access_token", userInfo?.access_token);
    setTimeout(() => props.history.push("/pipelines"), 2000);
  }
  
  return (
    <>
    <div className="signup_page">
      <div className="signup_page-container">
        <h1 className="signup_page-container-heading">Create Your Account</h1>
        <div className="signup_page-container-form">
          <label className="signup_page-container-form-label">Name</label>
          <Input
            name="fullName"
            value={fullName}
            onChange={(event) => onChangeName(event)}
            className="signup_page-container-input"
            required
          />
          <label className="signup_page-container-form-label">Email</label>
          <Input
            name="email"
            onChange={(event) => onChangeEmail(event)}
            type="email"
            className="signup_page-container-input"
          />
          <label className="signup_page-container-form-label">Password</label>
          <Input
            name="password"
            onChange={(event) => onChangePassword(event)}
            type="password"
            className="signup_page-container-input"
          />
          <Button
            type="LARGE"
            className="signup_page-container-button"
            onClick={() => registerUserData()}
          >
            Sign Up
          </Button>
        </div>
        <p className="signup_page-container-text">
          Already have an account?
          <Link to="/signin" className="signup_page-container-link">
            {" "}
            Sign In{" "}
          </Link>
        </p>
      </div>
 
    </div>
      <Footer/>
    </>
  );
};
export default SignUpPage;

import types from "../types/pipelineListingTypes";

const initialState = {
  createProjectData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CREATE_PROJECT_DATA:
      return { ...state, createProjectData: action.data };
    case types.TRAIN_GCP_TEXT:
      return { ...state, createProjectData: action.data };
    case types.TRAIN_GCP_IMG:
      return { ...state, createProjectData: action.data };
    case types.TRAIN_GCP_OBJ:
      return { ...state, createProjectData: action.data };
    case types.TRAIN_GCP_NER:
      return { ...state, createProjectData: action.data };

    default:
      return state;
  }
};

export default reducer;

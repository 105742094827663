import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import PageTitleBanner from "../../components/page-title-banner-component/PageTitleBanner";
import DataTable from "../../components/datatable-component/DataTable";
import Modal from "../../components/modal-component/Modal";
import Popup from "../../components/popup-component/Popup";
import Input from "../../components/input-component/Input";
import Dropdown from "../../components/dropdown-component/Dropdown";
import RadioGroup from "../../components/radio-group-component/RadioGroup";
import Button from "../../components/button-component/Button";
import awsLogo from "../../img/aws.svg";
import gcpLogo from "../../img/gcp.svg";
import deleteAlert from "../../img/delete-alert.svg";
import deleteGrey from "../../img/delete-grey.svg";
import "./Dashboard.scss";
import { stageValueConfig } from "../../utils/config/statusMapping.config";
import { useDispatch, useSelector } from "react-redux";
import {
  getPipelineData,
  deletePipelineByID,
} from "../../actions/pipelineListingAction";
import { postPipeline } from "../../actions/createPipelineAction";
import Footer from "../../components/footer-component/Footer.jsx";
import types from "../../types/pipelineListingTypes";
import {
  createNerDataset,
  datasetImageClassification,
  datasetObjectClassification,
  datasetTextClassification,
} from "../../actions/importDatasetAction";

const options = [
  {
    label: "AWS",
    value: "aws",
    image: awsLogo,
  },
  {
    label: "GCP",
    value: "gcp",
    image: gcpLogo,
  },
];
const dropdownOptions = [
  {
    id: 1,
    title: "Multi Class Image Classification",
    key: "image_classification_multi_class",
  },
  {
    id: 2,
    title: "Multi Label Image Classification",
    key: "image_classification_multi_label",
  },
  {
    id: 3,
    title: "Multi Class Text Classification",
    key: "text_classification_multi_class",
  },
  {
    id: 4,
    title: "Multi Label Text Classification",
    key: "text_classification_multi_label",
  },
  {
    id: 5,
    title: "Object Detection",
    key: "object_detection",
  },
  {
    id: 6,
    title: "Natural Entity Recognition",
    key: "natural_entity_recognition",
  },
];

const Dashborad = (props) => {
  const [modalState, setModalState] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [ctaData, setCtaData] = useState(null);
  const [pipelineName, setPipelineName] = useState("");
  const [serviceProvider, setserviceProvider] = useState("");
  const [isListOpen, setIsListOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  let pipelineData = useSelector(
    (state) => state.pipelineListing?.pipelineListing
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPipelineData());
  }, []);

  const createPipeline = (e) => {
    e.preventDefault();
    const selectedUseCase = getSelectedItem(selectedItem).key;
    const data = {
      pipeline_name: pipelineName,
      pipeline_description: pipelineName,
      cloud_service_provider: serviceProvider,
      annotation_project_name: pipelineName,
      use_case: selectedUseCase,
    };
    handleModalClose();
    dispatch(postPipeline(data))
      .then((response) => {
        dispatch({
          type: types.NEW_PIPELINE,
          response,
        });
        dispatch({
          type: types.POPUP_TRIGGER,
          data: {
            message:
              "Pipeline created successfully. Sync Mobius with your data source.",
            type: "success",
          },
        });
        
        if (serviceProvider.toLowerCase() === "gcp") {
          const postData = {
            pipeline_id: response.pipeline_id,
            display_name:
              response.annotation_project_name + response.pipeline_id,
            multi_label: false,
          };

          switch (selectedUseCase) {
            case "image_classification_multi_label":
              dispatch(datasetImageClassification(postData));
              break;
            case "image_classification_multi_class":
              dispatch(datasetImageClassification(postData));
              break;
            case "text_classification_multi_label":
              dispatch(datasetTextClassification(postData));
              break;
              case "text_classification_multi_class":
                dispatch(datasetTextClassification(postData));
                break;
            case "object_detection":
              dispatch(datasetObjectClassification(postData));
              break;
            case "natural_entity_recognition":
              dispatch(createNerDataset(postData));
              break;
            default:
              return null;
          }
        }

        props.history.push(`pipelinedetails/${response.pipeline_id}`);
      })
      .catch((_) => {
        dispatch({
          type: types.POPUP_TRIGGER,
          data: {
            message: "There was an unexpected error",
            type: "error",
          },
        });
      });
  };

  const onRowClick = ({ pipeline_id }) => {
    props.history.push(`pipelinedetails/${pipeline_id}`);
  };

  const checkDisableDelete= (status)=>{
    const stages= ["MODEL_DEPLOYING","MODEL_TRAINING","MODEL_UNDEPLOYING"]
    return stages.indexOf(status) > -1

  }
  const handleModalClose = () => {
    setPipelineName("");
    setserviceProvider("");
    setSelectedItem("");
    setModalState(false);
  };
  const handleDeleteCTA = (value) => {
    setCtaData(value);
    setDeleteModal(true);
  };

  const handleReset = () => {
    setDeleteModal(false);
    setCtaData(null);
  };

  const handleDeletePipeline = () => {
    const { pipeline_id } = ctaData;
    dispatch(deletePipelineByID(pipeline_id)).finally((_) => handleReset());
  };

  const getSelectedItem = (id) =>{
    return dropdownOptions.find(entry=>entry.id===id)
  }
  const columnHeadings = [
    {
      columnName: "",
      columnDataKey: "image",
    },
    {
      columnName: "Pipeline Name",
      columnDataKey: "pipeline_name",
      dataFormatting(value) {
        return value;
      },
    },
    {
      columnName: "created by",
      columnDataKey: "created_by",
      dataFormatting(value) {
        return value;
      },
    },
    {
      columnName: "use case",
      columnDataKey: "use_case",
      dataFormatting(value) {
        return value.replaceAll("_", " ");
      },
    },
    {
      columnName: "updated at",
      columnDataKey: "updated_at",
      dataFormatting(value) {
        return dateFormat(new Date(value), "dd/mm/yyyy");
      },
    },
    {
      columnName: "Last Update",
      columnDataKey: "current_stage",
      dataFormatting(value) {
        return stageValueConfig[value];
      },
    },
    {
      columnName: "",
      columnDataKey: "CTA",
      cta(value) {
        return (
          <div className="cta-container">
            <img
              onClick={() => handleDeleteCTA(value)}
              src={deleteGrey}
              alt="deletePipeline"
              className={checkDisableDelete(value?.current_stage)?"disabled":""}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div className="dashboard-wrapper">
      <Popup />
      <PageTitleBanner
        leftSideProp={<div>Pipeline Library</div>}
        rightSideProp={
          <Button
            onClick={() => setModalState(true)}
            className="page-header-create-btn"
          >
            Create New
          </Button>
        }
      />
      <div className="dashboard-pipeline-table-wrapper">
        <DataTable
          onRowClick={onRowClick}
          columnHeadings={columnHeadings}
          data={pipelineData}
          rowClassName="dashboard-pipeline-table-row"
          rowKey="pipeline_id"
          isFilter={true}
        />
      </div>
      <Modal
        title="Pipeline Configuration"
        modalState={modalState}
        className="pipeline-config-form"
        onClose={handleModalClose}
      >
        <form>
          <Input
            label="Pipeline Name"
            value={pipelineName}
            name="pipelineName"
            onChange={({ value }) => setPipelineName(value)}
            required
          />
          <RadioGroup
            label="Service provider"
            options={options}
            name="serviceProvider"
            onSelect={({ value, event }) => setserviceProvider(value)}
            selected={serviceProvider}
          />
          <Dropdown
            label="Use case"
            title={selectedItem ? getSelectedItem(selectedItem).title: "Select"}
            className="form-dropdown"
            list={dropdownOptions}
            isListOpen={isListOpen}
            setIsListOpen={setIsListOpen}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
          />
          <div className="pipeline-config-create">
            <Button
              type="submit"
              disabled={!(pipelineName && serviceProvider && selectedItem)}
              onClick={createPipeline}
            >
              Create
            </Button>
          </div>
        </form>
      </Modal>

      <Modal
        title={`Are you sure you want to delete ${ctaData?.pipeline_name}?`}
        modalState={deleteModal}
        onClose={() => handleReset()}
        className="delete-conform-modal"
      >
        <div className="delete-conform-modal-wrapper">
          <div className="message-wrapper">
            <img alt="warningIcon" src={deleteAlert} />
            <div className="message">
              Once you delete the pipeline, you will loose all the model
              versions which are associated with it.
            </div>
          </div>
          <div className="button-group">
            <Button
              theme="ghost"
              className="add-more"
              onClick={() => handleDeletePipeline()}
            >
              Delete
            </Button>
            <Button onClick={() => handleReset()}>Cancel</Button>
          </div>
        </div>
      </Modal>
      <Footer />
    </div>
  );
};

export default Dashborad;

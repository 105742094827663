import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAllTasks,
  deleteDataset,
  getDatasetByID,
  importNewDataset,
} from "../../actions/importDatasetAction";
import Button from "../../components/button-component/Button";
import Input from "../../components/input-component/Input";
import importIcon from "../../img/import-icon.svg";
import syncIcon from "../../img/sync.svg";
import deleteAlert from "../../img/delete-alert.svg";
import "./ImportDataset.scss";
import Modal from "../../components/modal-component/Modal";
import types from "../../types/pipelineListingTypes";

export const ImportDataset = (props) => {
  const [datasetURL, setDatasetURL] = useState("");
  const [deleteModal, setDeleteModal] = useState(null);
  const [imported, setImported] = useState(false);
  const dispatch = useDispatch();
  const projectDetails = useSelector(
    (state) => state.pipelineListing?.pipelineFlow
  );

  const annotaitionUri = useSelector(
    (state) => state.labelStudioDetails.transformAnnotationUri
  );
  const preDatasetDetails = useSelector((state) => {
    return state.importDataset?.gcpDataset;
  });

  const stage = useSelector((state) => {
    return state.pipeLineDetails?.stage;
  });

  useEffect(() => {
    if (projectDetails.pipeline_id) {
      dispatch(
        getDatasetByID({
          pipeline_id: projectDetails?.pipeline_id,
        })
      );
    } else {
      console.log("NO DATA");
    }
  }, [projectDetails]);

  useEffect(() => {
    if (annotaitionUri) {
      setDatasetURL(annotaitionUri);
    } else {
      setDatasetURL(projectDetails?.transform_annotation_uri);
    }
  }, []);

  const onChangeURL = ({ event }) => {
    setDatasetURL(event?.target?.value);
  };
  const onImport = () => {
    const id = projectDetails?.pipeline_id;

    dispatch(
      importNewDataset({
        pipeline_id: id,
        dataset_id: preDatasetDetails.dataset_id,
        gcs_path: datasetURL,
      })
    );
    setImported(true);
  };

  const handleDeleteImport = () => {
    dispatch(
      deleteAllTasks({
        pipeline_id: projectDetails?.annotation_project_id,
      })
    ).then(()=>{
      dispatch(
        deleteDataset({
          region: "us-central1",
          dataset_id: deleteModal,
        })
      ).then(() => {
        setDeleteModal(null);
      });
    })
    
  };

  return (
    <div>
      <div className="import-data">
        <h1 className="import-data-heading">Label File Path</h1>
        <div className="import-data-add">
          <Input
            className="import-data-add-input"
            name="pipelineName"
            onChange={(event) => onChangeURL(event)}
            value={datasetURL}
            placeholder="Path"
          />
          <Button
            disabled={datasetURL === ""}
            className="import-data-add-button"
            onClick={() => onImport()}
          >
            <img
              src={importIcon}
              alt="import-new"
              className={`configure-data-source-sync-card-delete ${
                datasetURL === "" ? "disable-delete" : null
              }`}
            />
          </Button>
          <p className="import-data-add-note">
            TIP: It is the path of the cloud bucket where the labelled data is
            exported. You can update an existing dataset or create a new
            one.east 100 samples should be labelled to create an efficient
            model.
          </p>
        </div>
      </div>
      {preDatasetDetails?.dataset_id ? (
        <div className="import-data-details">
          <div className="import-data-details-header">
            <h1 className="import-data-details-header-name">
              {preDatasetDetails.dataset_name}
            </h1>

            {imported && (
              <div className="import-data-status">
                <img
                  className="import-data-status-rotate loading"
                  src={syncIcon}
                  alt="syncing"
                />
                <span className="import-data-status-title">Importing</span>
              </div>
            )}
          </div>
          {/* <img
          className="import-data-delete"
          src={deleteIcon}
          alt="delete"
          onClick={() => setDeleteModal(preDatasetDetails?.dataset_id)}
        /> */}
        </div>
      ) : null}
      <div className="page-container-footer">
        {/* <Button
					theme='ghost'
					className='page-container-footer-button'
					onClick={routeChange}>
					Save & Exit
				</Button> */}
        <Button
          disabled={props.disable}
          onClick={() =>
            dispatch({
              type: types.SET_STAGE,
              data: {
                stage: stage + 1,
              },
            })
          }
          className="page-container-footer-button"
          // disabled={disable}
        >
          Proceed
        </Button>
      </div>
      <Modal
        title="Are you sure you want to remove the data source?"
        modalState={!(deleteModal === null)}
        onClose={() => setDeleteModal(null)}
        className="delete-conform-modal"
      >
        <div className="delete-conform-modal-wrapper">
          <div className="message-wrapper">
            <img alt="warningIcon" src={deleteAlert} />
            <div className="message">
              Once a data source is removed, we lose the respective labelled
              data as well. The already trained models will remain unaffected.
            </div>
          </div>
          <div className="button-group">
            <Button
              theme="ghost"
              className="add-more"
              onClick={() => handleDeleteImport()}
            >
              Remove
            </Button>
            <Button onClick={() => setDeleteModal(null)}>Cancel</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

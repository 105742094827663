import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import mobiusLogo from "../../img/icon-mobius-logo.svg";
import seperator from "../../img/icon-seperator.png";
import arrowProfileDown from "../../img/arrow-profile-down.svg";
import { logoutUser } from "../../actions/loginAction";
import { setDevice } from "../../actions/responsiveViewActions";
import hamburgerIcon from "../../img/hamburger-menu.svg";
import closeIcon from "../../img/close-white.svg";
import { withRouter } from "react-router-dom";
import "./Header.scss";
import Popup from "../popup-component/Popup";

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

const Header = (props) => {
	const userInfo = useSelector((state) => state?.loginUser?.user);
	const existingType = useSelector((state) => state.responsiveView.breakpoint);
	const [profileDropdown, setProfileDropdown] = useState(false);
	const [respHeaderNav, setRespHeaderNav] = useState(false);

	const dispatch = useDispatch();

	const existingTypeRef = React.useRef();
	const profileModal = React.useRef();
	useEffect(() => {
		window.addEventListener("resize", handleResize);
		document.addEventListener("click", handleClickOutside, true);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
			document.addEventListener("click", handleClickOutside, true);
		};
	}, []);

	useEffect(() => {
		existingTypeRef.current = existingType;
	}, [existingType]);

	const handleClickOutside = (event) => {
		if (profileModal.current && !profileModal.current.contains(event.target)) {
			setProfileDropdown(false);
		}
	};

	const handleResize = () => {
		const dimensions = getWindowDimensions();
		let device;
		if (dimensions.width < 480) {
			device = "MOBILE";
		} else if (dimensions.width < 961) {
			device = "TABLET";
		} else if (dimensions.width >= 961) {
			device = "DESKTOP";
		}
		if (existingTypeRef?.current !== device) {
			dispatch(setDevice(device));
		}
	};
	const redirectUser = () => {
		if (userInfo.access_token) {
			props.history.push("/pipelines");
		} else {
			props.history.push("/");
		}
	};

	const handleLogout = () => {
		setProfileDropdown(false);
		dispatch(logoutUser());
		setTimeout(() => props.history.push("/signin"), 10);
	};

	return (
		<div>
			<div className='header-container'>
				<Popup />
				<div className='header-left-side' onClick={() => redirectUser()}>
					<div className='mobius-logo'>
						<img src={mobiusLogo} alt='mobius logo' />
					</div>
					<img src={seperator} alt='|' />
					<div className='state-info'>{process.env.REACT_APP_ORG}</div>
				</div>
				{existingType === "DESKTOP" ? (
					<div className='header-right-side'>
						{(sessionStorage.getItem("access_token")) && (
							<div
								className='header-user-info'
								ref={profileModal}
								onClick={() => setProfileDropdown(!profileDropdown)}>
								<span>
								{  sessionStorage.getItem("userName") }
								</span>
								<img
									src={arrowProfileDown}
									className={profileDropdown && "arrow-up"}
									alt='arrow-down'
								/>
								{profileDropdown && (
									<ul
										className='profile-dropdown'
										onClickOutside={() => setProfileDropdown(false)}>
										<li className='profile-dd-list' type='link'>
											<span
												className='dropdown-button'
												onClick={() => handleLogout()}>
												Logout
											</span>
										</li>
									</ul>
								)}
							</div>
						)}
						<button className='header-button' onClick={() => {}}>
							Help
						</button>
					</div>
				) : (
					<div className='header-right-side mobile'>
						<img
							src={respHeaderNav ? closeIcon : hamburgerIcon}
							alt='hamburger Icon'
							onClick={() => setRespHeaderNav(!respHeaderNav)}
						/>
					</div>
				)}
			</div>
			{respHeaderNav && existingType !== "DESKTOP" && (
				<div className='header-responsive-container'>
					<nav>Help</nav>
					<nav>Documentation</nav>
					{userInfo.access_token && (
						<nav onClick={() => handleLogout()}>Logout</nav>
					)}
				</div>
			)}
		</div>
	);
};
export default withRouter(Header);

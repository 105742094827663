import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../input-component/Input";
import Dropdown from "../dropdown-component/Dropdown";
import Button from "../button-component/Button";
import {
  createImgRecogProject,
  createDocClassifier,
  startProjectTraining,
  createEntityRecognizer,
  gcpImgTrainModel,
  gcpNERTrainModel,
  gcpTextTrainModel,
  gcpObjTrainModel,
} from "../../actions/trainModelAction";
import { trainModelDropdownOptions } from "../../constants/Constants";
import "./TrainModel.scss";
import Modal from "../modal-component/Modal";
import { getModelData, getProjectByID } from "../../actions/manageModelAction";

const TrainModel = (props) => {
  const dispatch = useDispatch();
  const {
    use_case,
    pipeline_id,
    cloud_service_provider,
    transform_annotation_uri,
  } = useSelector((state) => {
    return state?.pipelineListing?.pipelineFlow;
  });
  const transformAnnotationUri =
    useSelector((state) => {
      return state?.labelStudioDetails?.transformAnnotationUri;
    }) || transform_annotation_uri;

  const { dataset_id, dataset_name } = useSelector((state) => {
    return state?.importDataset.gcpDataset;
  });

  const [modelName, setModelName] = useState("");
  const [labelFilePath, setLabelFilePath] = useState("");

  useEffect(() => {
    transformAnnotationUri && setLabelFilePath(transformAnnotationUri);
  }, [transformAnnotationUri]);

  useEffect(() => {
    setModelName(dataset_name);
  }, [dataset_name]);

  const proceedToManageModel = async (e) => {
    e.preventDefault();
    if (cloud_service_provider.toLowerCase() === "aws") {
      switch (use_case) {
        case "image_classification_multi_class":
        case "image_classification_multi_label":
        case "object_detection":
            const tempArrayImg = labelFilePath.split("//");
            const bucketNameImg = tempArrayImg[1].split("/")[0];
            const index = labelFilePath.indexOf(bucketNameImg);
            const startTrainingString = labelFilePath.substr(
              index + 1 + bucketNameImg.length
            );
            dispatch(
              startProjectTraining({
                pipeline_id: pipeline_id,
                version_name: modelName,
                output_config: {
                  S3Bucket: bucketNameImg,
                  S3KeyPrefix: "Output",
                },
                training_data: {
                  Assets: [
                    {
                      GroundTruthManifest: {
                        S3Object: {
                          Bucket: bucketNameImg,
                          Name: startTrainingString,
                        },
                      },
                    },
                  ],
                },
                testing_data: {
                  AutoCreate: true,
                },
              })
            )
              .then((_) => {
                getModels();
              })
              .finally((_) => props.handleModalClose());
    
          break;
        case "text_classification_multi_class":
        case "text_classification_multi_label":

          const tempArray = labelFilePath.split("//");
          const bucketName = `${tempArray[0]}//${tempArray[1].split("/")[0]}`;

          return dispatch(
            createDocClassifier({
              pipeline_id: pipeline_id,
              DocumentClassifierName: modelName,
              // DataAccessRoleArn: pipelineListing?.arnData?.config_value, // to be added after api integration
              InputDataConfig: {
                DataFormat: "AUGMENTED_MANIFEST",
                AugmentedManifests: [
                  {
                    S3Uri: labelFilePath,
                    AttributeNames: ["text-classification"],
                  },
                ],
              },
              OutputDataConfig: {
                S3Uri: bucketName,
              },
              LanguageCode: "en",
            })
          )
            .then((_) => {
              getModels();
            })
            .finally((_) => props.handleModalClose());
        case "natural_entity_recognition":
          return dispatch(
            createEntityRecognizer({
              pipeline_id: pipeline_id,
              RecognizerName: modelName,
              // DataAccessRoleArn: pipelineListing?.arnData?.config_value, // to be added after api integration
              InputDataConfig: {
                DataFormat: "AUGMENTED_MANIFEST",
                AugmentedManifests: [
                  {
                    S3Uri: labelFilePath,
                    AttributeNames: ["named-entity-recognition"],
                  },
                ],
              },
              LanguageCode: "en",
            })
          )
            .then((_) => {
              getModels();
            })
            .finally((_) => props.handleModalClose());
        default:
          return null;
      }
    } else {
      switch (use_case) {
        case "image_classification_multi_class":
        case "image_classification_multi_label":
        case "object_detection":
          return dispatch(
            gcpImgTrainModel({
              pipeline_id: pipeline_id,

              dataset_id: dataset_id,
              model_display_name: modelName,
            })
          )
            .then((_) => {
              getModels();
            })
            .finally((_) => props.handleModalClose());
          
          case "text_classification_multi_class":
          case "text_classification_multi_label":
  
          return dispatch(
            gcpTextTrainModel({
              pipeline_id: pipeline_id,
              dataset_id: dataset_id,
              model_display_name: modelName,
            })
          )
            .then((_) => {
              getModels();
            })
            .finally((_) => props.handleModalClose());

        case "natural_entity_recognition":
          return dispatch(
            gcpNERTrainModel({
              pipeline_id: pipeline_id,
              dataset_id: dataset_id,
              model_display_name: modelName,
            })
          )
            .then((_) => {
              getModels();
            })
            .finally((_) => props.handleModalClose());
        default:
          return null;
      }
    }
  };

  const getSelectedItem = (id) => {
    return trainModelDropdownOptions.find((entry) => entry.id === id);
  };

  const getModels = () => {
    dispatch(
      getProjectByID({
        pipeline_id: pipeline_id,
      })
    ).then((res) => dispatch(getModelData(pipeline_id)));
  };
  return (
    <>
      <Modal
        title="New Version Details"
        modalState={props.modalState}
        className="pipeline-model-form"
        onClose={props.handleModalClose}
      >
        <form>
          <Input
            label="Model Name"
            value={modelName}
            name="pipelineName"
            onChange={({ value }) => setModelName(value)}
            required
          />
          <Input
            label="Label File Path"
            value={labelFilePath}
            name="LabelFilePath"
            onChange={({ value }) => setLabelFilePath(value)}
            required
          />
          <div className="pipeline-config-create">
            <Button
              type="submit"
              onClick={proceedToManageModel}
              className="page-container-footer-button"
              disabled={!(modelName && labelFilePath)}
            >
              Create
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};
export default TrainModel;

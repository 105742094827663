import { downloadJSON } from "../functions/nebulaHelper";
import types from "../types/pipelineListingTypes";
import {
  API_URL,
  RequestMethod,
  defaultHeader,
} from "../utils/config/api.config";

const setData = (data) => ({
  type: types.SET_PROJECT_DESCRIPTION,
  data,
});
export const updateOutputBucket = (data) => ({
  type: types.UPDATE_OUTPUT_BUCKET,
  data,
});
export const assistiveData = (data) => ({
  type: types.ASSISTIVE_LABEL_DATA,
  data,
});

export const getProjectDescription = (id) => async (dispatch) => {
  try {
    const response = await fetch(
      `${API_URL.getLabelDetails}?annotation_project_id=${id}`,
      {
        method: RequestMethod.GET,
        headers: defaultHeader(),
      }
    );
    const res = await response.json();
    dispatch(setData(res));
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const getCloudUri = (payload, stage) => async (dispatch) => {
  
    return new Promise(function(resolve,reject){
      try {
         fetch(
         `${API_URL.exportAnnotations}?${new URLSearchParams(payload).toString()}`,
         {
           method: RequestMethod.GET,
           headers: defaultHeader(),
         }
       ).then((res) => res.json()).then((res) => {
           const { pipeline_id, service_provider, bucket_name } = payload;
           dispatch({
             type: types.EXPORT_ANNOTATION,
             data: res.cloud_uri,
           });
          return fetch(
             `${API_URL.transformAnnotations}`,
             {
               method: RequestMethod.POST,
               headers: defaultHeader(),
               body: JSON.stringify({
                 pipeline_id,
                 service_provider,
                 output_data_bucket_name: bucket_name,
                 input_data_uri: res.cloud_uri,
                 output_data_file_prefix: "output",
               }),
             }
           );
         }).then(res=>res.json()).then((res) => {
           dispatch({
             type: types.TRANSFORM_ANNOTATION,
             data: res.cloud_uri,
           });
           dispatch({
            type: types.POPUP_TRIGGER,
            data: {
              message:
                "Export completed successfully. You can create a new model version by using the exported data. ",
              type: "success",
            },
          });
           resolve(res)
         }).catch(error=>{
          dispatch({
            type: types.POPUP_TRIGGER,
            data: {
              message: "An unexpected error occured",
              type: "error",
            },
          });
          reject(error)
         })
     } catch (error) {
      console.log((error)) 
        
     }
    })
   
};

export const setExportAnnotationUri = (uri) => (dispatch) => {};

export const setTransformAnnotationUri = (uri) => (dispatch) => {
  dispatch({
    type: types.SET_DEVICE,
    data: uri,
  });
};


export const downloadAnnotations = (params) => async (dispatch) => {
  try {
    const response = await fetch(
      API_URL.downloadConfig,
      {
        method: RequestMethod.POST,
        headers: defaultHeader(),
        body: JSON.stringify(params),
      }
    );
    const res = await response.json();
    downloadJSON(res)
  } catch (err) {
    console.log("ERROR", err);
  }
};

export const assistiveAnnotations = (params) => async (dispatch) => {
  try {
    const response = await fetch(
      API_URL.assistiveLabel,
      {
        method: RequestMethod.POST,
        headers: defaultHeader(),
        body: JSON.stringify(params),
      }
    );
    return await response.json();

  } catch (err) {
    console.log("ERROR", err);
  }
};

export const getDropdownValues = (params) => async (dispatch) => {
  try {
    const response = await fetch(
      `${API_URL.getExportConfig}?${new URLSearchParams(params).toString()}`,
      {
        method: RequestMethod.GET,
        headers: defaultHeader(),
      }
    );
    return await response.json();

  } catch (err) {
    console.log("ERROR", err);
  }
};

export const getImportConfigs = (params) => async (dispatch) => {
  try {
    const response = await fetch(
      `${API_URL.getImportConfig}?${new URLSearchParams(params).toString()}`,
      {
        method: RequestMethod.GET,
        headers: defaultHeader(),
      }
    );
    return await response.json();

  } catch (err) {
    console.log("ERROR", err);
  }
};
export const stepMapper = {
  gcp: {
    DATASET_CREATED: 1,
    DATASET_DELETED: 3,
    IMPORTING_DATASET: 3,
    IMPORT_DATASET: 3,
    MODEL_DEPLOYING: 4,
    MODEL_DEPLOYED: 4,
    MODEL_UNDEPLOYING: 4,
    MODEL_UNDEPLOYED: 4,
    MODEL_FAILED: 4,
    MODEL_EXPORTED: 4,
    MODEL_DELETED: 4,
    MODEL_TRAINING: 4,
    TRAINING_STOPPED: 4,
    TRAINING_COMPLETED:4,
    TRAINING_FAILED: 4,
    EXPORT_ANNOTATIONS: 3,
    TRANSFORM_ANNOTATIONS: 3,
    CREATE_ANNOTATION_PROJECT: 1,
    DATA_IMPORTED: 4
  },
  aws: {
    MODEL_DEPLOYING: 3,
    MODEL_DEPLOYED: 3,
    MODEL_UNDEPLOYING: 3,
    MODEL_UNDEPLOYED: 3,
    MODEL_DELETED: 3,
    MODEL_FAILED: 3,
    MODEL_EXPORTED: 3,
    MODEL_TRAINING: 3,
    PROJECT_DELETED: 1,
    TRAINING_STOPPED: 3,
    TRAINING_COMPLETED:3,
    TRAINING_FAILED: 3,
    PROJECT_CREATED: 1,
    EXPORT_ANNOTATIONS: 3,
    TRANSFORM_ANNOTATIONS: 3,
    CREATE_ANNOTATION_PROJECT: 1,
  },
};

export const trainModelDropdownOptions = [
  { id: 1, title: "Multi Label", key: "multi-label" },
  { id: 2, title: "Multi Class", key: "multi-class" },
];

import types from "../types/pipelineListingTypes";

const initialState = {
  user: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER:
      return { ...state, user: action.data };
    case types.REGISTER_USER:
      return { ...state, user: action.data };
    default:
      return state;
  }
};

export default reducer;

import types from "../types/pipelineListingTypes";

const initialState = {
  projectDescription: {},
  exportAnnotationUri: "",
  transformAnnotationUri: "",
  outputBucket: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PROJECT_DESCRIPTION:
      return { ...state, projectDescription: action.data };
    case types.EXPORT_ANNOTATION:
      return { ...state, exportAnnotationUri: action.data };
    case types.TRANSFORM_ANNOTATION:
      return { ...state, transformAnnotationUri: action.data };
    case types.UPDATE_OUTPUT_BUCKET:
      return { ...state, outputBucket: action.data };
    default:
      return state;
  }
};

export default reducer;

export const getApiFromUseCase = (API_URL, platform, type, useCase, params) => {
  if (platform === "aws") {
    if (
      useCase === "image_classification_multi_class" ||
      useCase === "image_classification_multi_label" ||
      useCase === "object_detection"

    ) {
      switch (type) {
        case "Deploy":
          return {
            url: API_URL.getDeployModelRekog,
            params: {
              pipeline_id: params.pipeline_id,
              project_version_arn: params.project_version_arn,
              min_inference_units: params.min_inference_units,
            },
          };
        case "Delete":
          return {
            url: API_URL.getDeleteModelRekog,
            params: {
              pipeline_id: params.pipeline_id,
              project_version_arn: params.project_version_arn,
            },
          };
        case "Undeploy":
          return {
            url: API_URL.getUnDeployModelRekog,
            params: {
              pipeline_id: params.pipeline_id,
              project_version_arn: params.project_version_arn,
            },
          };

        default:
          return "";
      }
    } else if (
      useCase === "text_classification_multi_class" ||
      useCase === "text_classification_multi_label"
    ) {
      switch (type) {
        case "Deploy":
          return {
            url: API_URL.getDeployModelComprehend,
            params: {
              pipeline_id: params.pipeline_id,
              model_arn: params.model_arn,
              min_inference_units: params.min_inference_units,
              endpoint_name: params.endpoint_name,
            },
          };
        case "Delete":
          return {
            url: API_URL.getDeleteDocClassifier,
            params: {
              pipeline_id: params.pipeline_id,
              arn: params.DocumentClassifierArn,
            },
          };

        case "Undeploy":
          return {
            url: API_URL.getUnDeployModelComprehend,
            params: {
              pipeline_id: params.pipeline_id,
              endpoint_arn: params.endpoint_arn,
              model_arn: params.model_arn,
            },
          };

        default:
          return "";
      }
    } else if (useCase === "natural_entity_recognition") {
      switch (type) {
        case "Deploy":
          return {
            url: API_URL.getDeployModelComprehend,
            params: {
              pipeline_id: params.pipeline_id,
              model_arn: params.model_arn,
              min_inference_units: params.min_inference_units,
              endpoint_name: params.endpoint_name,
            },
          };
        case "Delete":
          return {
            url: API_URL.getDeleteEntityRecognizer,
            params: {
              pipeline_id: params.pipeline_id,
              arn: params.EntityRecognizerArn,
            },
          };
        case "Undeploy":
          return {
            url: API_URL.getUnDeployModelComprehend,
            params: {
              pipeline_id: params.pipeline_id,
              endpoint_arn: params.endpoint_arn,
              model_arn: params.model_arn,
            },
          };
        default:
          return "";
      }
    }
  } else if (platform === "gcp") {
    switch (type) {
      case "Deploy":
        return {
          url: API_URL.gcpDeployModel,
          params: {
            pipeline_id: params.pipeline_id,
            project_id: params.project_id,
            model_id: params.model_id,
            region: params.region,
          },
        };
      case "Delete":
        return {
          url: API_URL.gcpDeleteModel,
          params: {
            project_id: params.project_id,
            model_id: params.model_id,
            region: params.region,
          },
        };

      case "Undeploy":
        return {
          url: API_URL.gcpUndeployModel,
          params: {
            pipeline_id: params.pipeline_id,
            project_id: params.project_id,
            model_id: params.model_id,
            region: params.region,
          },
        };

      default:
        return "";
    }
  }
};

const getStatus = (value) => {
  switch (value.status.toLowerCase()) {
    case "training_completed":
      return "Trained";
    case "model_deployed":
      return "Deployed";
    case "model_training":
      return "Training";
    case "model_deleted":
      return "Deleting";
    case "training_failed":
      return "Failed";
    case "project_deleted":
      return "Deleting";
    case "model_deploying":
      return "Deploying";
    case "model_undeployed":
      return "Undeployed";
    case "model_failed":
      return "Failed"
    case "stopping":
      return "Stopping";
    case "stopped":
      return "";
    default:
      return "";
  }
};

export const parseManageModelData = (data) => {
  return data.map((value) => {
    const status = getStatus(value);
    return {
      checked: value.UUID,
      checkboxDisabled:
        status === "Deployed" || status === "Trained" || status === "Undeployed"
          ? false
          : true,
      version: value.alias_name,
      uuid: value.UUID,
      modifiedDate: value.updated,
      alias_name: value.alias_name,
      f1Score: value.model_monitoring_data.length
        ? value.model_monitoring_data[0].model_f1_score
        : "-",
      precision: value.model_monitoring_data.length
        ? value.model_monitoring_data[0].model_precision
        : "-",
      recall: value.model_monitoring_data.length
        ? value.model_monitoring_data[0].model_recall
        : "-",
      status: status,
      modelId: value.model_id,
      model_deployment_data: value.model_deployment_data,
    };
  });
};

import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMonitorData } from "../../actions/manageModelAction";
import Button from "../../components/button-component/Button";
import PageTitleBanner from "../../components/page-title-banner-component/PageTitleBanner";
import arrow from "../../img/back-arrow.svg";
import "./ModelMonitor.scss";
import dateFormat from "dateformat";
import { stageValueConfig } from "../../utils/config/statusMapping.config";
import copyBlue from "../../img/copy-blue.svg";
import { getPipelinesFlow } from "../../actions/pipelineListingAction";
import awsLogo from "../../img/aws.svg";
import gcpLogo from "../../img/gcp.svg";
import { copyContent } from "../../functions/nebulaHelper";
import InferenceLayout from "../inference-layout/InferenceLayout";
import Footer from "../../components/footer-component/Footer";
import types from "../../types/pipelineListingTypes";

function ModelMonitor(props) {
  const [selectedOption, setSelectedOption] = useState("");
  const data = useSelector((state) => state.manageModel.modelMonitorData);
  const dispatch = useDispatch();
  const projectDetails = useSelector(
    (state) => state.pipelineListing?.pipelineFlow
  );

  useEffect(() => {
    const {
      match: { params },
      location: { pathname },
    } = props;
    const location = params?.model_id + pathname.split(params?.model_id).pop();
    dispatch(getMonitorData(location));

    const param = {
      pipeline_id: params.id,
    };
    dispatch(getPipelinesFlow(param));
    dispatch({
      type: types.GET_INFERENCE_DATA,
      data: {},
    });

    dispatch({
      type: types.GET_MODEL_MONITOR,
      data:{}
    })
  }, []);

  useEffect(() => {
    data?.tags_and_labels &&
      setSelectedOption(Object.keys(data?.tags_and_labels)[0]);
  }, [data]);

  const goBack = () => {
    const {
      match: { params },
    } = props;
    props.history.push(`/pipelinedetails/${params.id}`);
  };

  const metrices = [
    {
      title: "F1 Score",
      value: data.model_monitoring_data?.length
        ? data.model_monitoring_data[0]?.model_f1_score
        : "N.A",
    },
    {
      title: "Precision",
      value: data.model_monitoring_data?.length
        ? data.model_monitoring_data[0]?.model_precision
        : "N.A",
    },
    {
      title: "Recall",
      value: data.model_monitoring_data?.length
        ? data.model_monitoring_data[0]?.model_recall
        : "N.A",
    },
    {
      title: "Training Samples Count",
      value: data?.manifest_data && data?.manifest_data.training_sample_count,
    },
    {
      title: "Test Samples Count",
      value: data?.manifest_data && data?.manifest_data.testing_sample_count,
    },
  ];

  const details = [
    {
      title: "Model Name",
      value: data.alias_name,
    },
    {
      title: "Model ID",
      value: data.UUID,
    },
    {
      title: "Created",
      value: data.created && dateFormat(new Date(data?.created), "dd/mm/yyyy"),
    },
    {
      title: "Modified Date",
      value: data.updated && dateFormat(new Date(data?.updated), "dd/mm/yyyy"),
    },
    {
      title: "Model Status",
      value: data.status ? stageValueConfig[data.status] : "-",
    },
    {
      title: "Artifacts",
      value: data.artifacts ? data.artifacts.S3Uri : "-",
    },
    {
      title: "Dataset Id",
      value: data?.dataset_id?.AugmentedManifests
        ? data?.dataset_id?.AugmentedManifests[0]?.S3Uri
        : "-",
    },
  ];

  const setLogoImage = (platform) => {
    switch (platform) {
      case "aws":
        return awsLogo;
      case "gcp":
        return gcpLogo;
      default:
        break;
    }
  };

  return (
    <div className="main-model-montior">
      <PageTitleBanner
        leftSideProp={<div>Pipeline Details</div>}
        rightSideProp={
          <Button className="page-header-create-btn" disabled={true}>
            Create New Model
          </Button>
        }
      />

      <div className="model-monitoring-page">
        <div className="pipeline-details-header">
          <div className="pipeline-details">
            <div className="pipeline-id-container">
              <span className="pipeline-name">
                {projectDetails?.pipeline_id}
              </span>
              <span className="pipeline-desc">
                {projectDetails?.pipeline_name?.replace("_", " ")}
              </span>
            </div>
            <span className="pipeline-usecase">
              {projectDetails?.use_case?.replace("_", " ")}
            </span>
          </div>
          <div className="pipeline-img">
            <img
              src={setLogoImage(projectDetails?.cloud_service_provider)}
              alt="img"
            />
          </div>
        </div>
        <div className="redirect-back">
          <img src={arrow} alt="" onClick={() => goBack()} />
          <h1>Model Monitoring</h1>
        </div>
        <div className="training-metrices">
          <h1 className="training-metrices-title">Training Metrics</h1>
          <p className="training-metrices-text">
            These metrics are based on model training. These are not affected by
            the real-time performance of a deployed model.
          </p>
          <div className="metrices-stats">
            {metrices.map((matrix) => {
              return (
                <div className="stat-card">
                  <h3 className="stat-card-title">{matrix.title}</h3>
                  <p className="stat-card-text">{matrix.value}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="model-details-container">
          <div className="model-details">
            <h1 className="model-details-title">Model Details</h1>
            <p className="model-details-text">AWS</p>
            <div className="model-details-stats">
              {details.map((detail) => (
                <div className="detail">
                  <p className="detail-title">{detail.title}</p>
                  <span className="detail-value">{detail.value}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="endpoint-arn">
            <div className="endpoint-arn-header">
              <h1 className="endpoint-arn-title">Endpoint ARN</h1>
              <p className="endpoint-arn-text">
                Unique ID allocated by AWS to run inference over the endpoint
              </p>
            </div>
            {data && data?.model_deployment_data?.length ? (
              <div className="copy-text-section">
                <p>{data?.model_deployment_data[0]?.deployment_endpoint}</p>
                <div
                  className="copy-arn"
                  onClick={() =>
                    copyContent(
                      data?.model_deployment_data[0]?.deployment_endpoint
                    )
                  }
                >
                  <img src={copyBlue} alt="" />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="tags-and-labels">
            <div className="tags-and-labels-header">
              <div className="title-section">
                <h1 className="model-details-title">Tags and Labels</h1>
                <p className="model-details-text">
                  Annotations of the data for the project
                </p>
              </div>
              <div className="dropdown-section">
                {data?.tags_and_labels && (
                  <select
                    className="select-label-category"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  >
                    {Object.keys(data.tags_and_labels).map((i) => (
                      <option key={i}>{i}</option>
                    ))}
                  </select>
                )}
              </div>
            </div>

            <div className="tags-and-labels-body">
              {data?.tags_and_labels &&
                selectedOption &&
                data?.tags_and_labels[selectedOption]?.length &&
                data?.tags_and_labels[selectedOption]?.map((entry, i) => (
                  <span key={entry}>{entry}</span>
                ))}
            </div>
          </div>
        </div>
        {data && data?.model_deployment_data?.length ? (
          <InferenceLayout
            useCase={projectDetails?.use_case}
            endpointArn={data?.model_deployment_data[0]?.deployment_endpoint}
          />
        ) : null}
      </div>
      <Footer />
    </div>
  );
}

export default ModelMonitor;

import React from "react";
import deleteIcon from "../../img/delete-alert.svg";

const DeleteModalBody = (props) => {
    return (
    <div className="delete-modal-body">
      <img src={deleteIcon} alt="delete icon" />
      <div className="body-text">
        Deleting a model won't affect your datasets.Model deletion can take upto 5 minutes.
      </div>
    </div>
        
  );
};

export default DeleteModalBody;
